.CallSupportModal .modal-body .nav  .nav-item .nav-link{    
    margin: 5px 0px;
    border: 1px solid #f88d28 ;
    background-color: #fff;
    color: #f88d28;
}
.CallSupportModal .modal-body .nav  .nav-item .nav-link.active{
    background-color: #f88d28;
    border: 1px solid #f88d28 ;
    color: #fff;
}
.CallSupportModal .modal-body .submitbtn{
    border: 1px solid #f88d28 ;
    background-color: #f88d28;
    color: #fff;
    width: fit-content;
}