.Product-Detail {
    margin-top: 3%;
}

.Product-Detail .background-product {
    height: 499px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
}
.Product-Detail .background-product .main .product-detail  .product-detail-main {
    aspect-ratio: 3/3;
}
.Product-Detail .background-product .main .product-detail .product-detail-main img.product-img {
    /* margin-top: 18%; */
    width: 100%;
    height: 100%;
    /* margin-left: 12%; */
}

.Product-Detail .background-product .main .product-detail.text-center {
    padding-top: 44px;
}

.Product-Detail .gallery .gallery-imgs {
    text-align: center;
    width: 76px;
    height: 82px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 7px;
}

.Product-Detail .background-product .main .product-detail {
    position: relative;
    width: 468px;
    height: 499px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Product-Detail .background-product .main .product-detail #buttonWrapper {
    position: relative;
    bottom: 23px;
    right: 17px;
}

.Product-Detail .fullscreen.fullscreen-enabled {
    background: #fff;
}

.Product-Detail .background-product .main {
    text-align: -webkit-center;
}

.Product-Detail .background-product .main .main-section .Buttons .minus {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 50px;
}

.Product-Detail .background-product .main .main-section .Buttons .minus:hover,
.Product-Detail .background-product .main .main-section .Buttons .plus:hover {
    background: #25304e 0% 0% no-repeat padding-box;
}

.Product-Detail .background-product .main .main-section .Buttons .minus:hover .btn-minus,
.Product-Detail .background-product .main .main-section .Buttons .plus:hover .btn-plus {
    color: #fff;
}

.Product-Detail .background-product .main .main-section .Buttons .plus {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 50px;
}

.Product-Detail .gallery .swiper-wrapper {
    padding: 0px 15px;
}

.Product-Detail .gallery .swiper {
    padding: 10px 0px;
}

.Product-Detail .gallery {
    margin-bottom: 5%;
    margin-top: 4%;
}

.Product-Detail .background-product .main .main-section .Buttons .plus .btn-plus {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Product-Detail .background-product .main .main-section .Buttons .minus .btn-minus {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Product-Detail .background-product .main .main-section {
    bottom: 9px;
    right: 15px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;
}

.Product-Detail .background-product .main .main-section .Buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Product-Detail .gallery .gallery-imgs img.inner-img {
    margin-top: 15px;
    padding: -1px 0px;
    width: 52px;
    height: 53px;
}

.Product-Detail .background-product .main .main-section .Buttons .border-line {
    width: 40px;
}

.Product-Detail .background-product .main .main-section .Buttons .range-slider {
    height: 2px;
    background: #929db9;
}

.Product-Detail .background-product .main .main-section .Buttons .range-slider .range-slider__range {
    background: #929db9;
    height: 0px;
}

.Product-Detail .background-product .main .main-section .Buttons .range-slider .range-slider__thumb {
    background: #929db9;
}

.Product-Detail .background-product .main .main-section .Buttons .range-slider .range-slider__thumb[data-disabled] {
    z-index: 2;
    display: none;
}

.Product-Detail .background-product .main .main-section .Buttons .border-line .range-slider .range-slider__thumb {
    width: 10px;
    height: 10px;
}

.Product-Detail .gallery .silder-btn {
    position: relative;
}

.Product-Detail .gallery .silder-btn .back-btn {
    content: url("../../../../public/assets/Image/Homepage/Popular-Categories/left.png");
    width: 15px;
    height: 15px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 29px;
    z-index: 999;
    left: -4px;
    filter: hue-rotate(45deg);
}

.Product-Detail .gallery .silder-btn .next-btn {
    content: url("../../../../public/assets/Image/Homepage/Popular-Categories/right.png");
    width: 15px;
    height: 15px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 31px;
    z-index: 999;
    right: -18px;
    filter: hue-rotate(45deg);
}

.Product-Detail .gallery .swiper-button-prev.swiper-button-disabled,
.Product-Detail .gallery .swiper-button-next.swiper-button-disabled {
    display: none;
}

.Product-Detail .gallery .swiper-button-prev,
.Product-Detail .gallery .swiper-button-next {
    display: none;
}

.Product-Detail .background-product {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.Product-Detail .background-product .close {
    top: 13px;
    position: absolute;
    right: 22px;
}

.Product-Detail .background-product .main .main-section .share .share-hover {
    display: none;
}

.Product-Detail .background-product .main .main-section .share:hover .share-hover {
    display: block;
    position: absolute;
    bottom: 21px;
    left: 17px;
    width: 41px;
    height: 22px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #00000029;
    border-radius: 3px;
}

.Product-Detail button.toggle-fullscreen-btn {
    background-color: transparent;
    border: none;
}

.Product-Detail .toggle:hover .toggle-text {
    display: block;
    bottom: 33px;
    position: absolute;
    width: 116px;
    height: 22px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 2px #00000029;
    border-radius: 3px;
}

.Product-Detail .toggle {
    position: relative;
}

.Product-Detail .toggle-text {
    display: none;
}

.Product-Detail .x-mark {
    color: #000;
}
.Product-Detail .breadcrumb-item a {
    color: #000;
}
.Product-Detail .breadcrumb-item + .breadcrumb-item::before {
    content: url("../../../../public/assets/Image/Icon/arrow-forward.png");
}
/* .breadcrumb-item+.breadcrumb-item::before {
  background-image: url('../../../../public/assets/Image/Vendor-Page/arrow.png');
  background-repeat: no-repeat;
} */

/* Media Query Product Detail css  */

@media (min-width: 0px) and (max-width: 320px) {
    .Product-Detail .background-product .main .product-detail {
        /* width: 100%; */
        width: 179px;
        height: 187px;
    }

    .Product-Detail .background-product .main .product-detail .product-detail-main img.product-img {
        height: 152px;
        margin-left: 0px;
        width: 167px;
    }

    .Product-Detail .background-product {
        height: 292px;
    }

    .Product-Detail .background-product .main .main-section {
        bottom: 6px;
    }

    .Product-Detail .gallery .silder-btn .next-btn {
        right: -20px;
    }
}

@media (min-width: 321px) and (max-width: 575px) {
    .Product-Detail .background-product .main .product-detail {
        width: 295px;
        height: 287px;
    }

    .Product-Detail .background-product .main .product-detail .product-detail-main img.product-img {
        width: 323px;
        height: 296px;
    }

    .Product-Detail .gallery .silder-btn .next-btn {
        right: -17px;
    }

    .Product-Detail .background-product .main .main-section {
        bottom: 7px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .Product-Detail .background-product .main .product-detail {
        width: 344px;
        height: 328px;
    }

    .Product-Detail .background-product .main .product-detail .product-detail-main img.product-img {
        width: 323px;
        height: 296px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .Product-Detail .gallery .silder-btn .next-btn {
        right: -23px;
    }

    .Product-Detail .background-product .main .product-detail {
        width: 410px;
        height: 355px;
    }

    .Product-Detail .background-product .main .product-detail .product-detail-main img.product-img {
        width: 323px;
        height: 296px;
    }

    .Product-Detail .background-product .main .main-section {
        bottom: 13px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .Product-Detail .background-product .main .product-detail {
        width: 414px;
        height: 407px;
    }

    .Product-Detail .gallery .silder-btn .next-btn {
        right: -20px;
    }
}
