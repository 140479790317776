.Home-Banner img.Banner-image {
  height: 100%;
  width: 100%;
  /* aspect-ratio: 1/0.35; */
}

/* .Home-Banner .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #df4223;
}

.Home-Banner .swiper-pagination-bullet {
  background: #df4223;
  opacity: 1;
} */
