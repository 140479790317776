.Coupon_Card {
  background: transparent
    linear-gradient(244deg, #f64f59 0%, #c471ed 54%, #12c2e9 100%);
  box-shadow: 0px 0px 15px #00000017;
  border-radius: 10px;
  width: 100%;
  height: 135px;
  min-height: 100%;
  padding: 5px 15px;
  position: relative;
  overflow: hidden;
}

.Coupon_Card .card-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Coupon_Card .card-holder .img-holder {
  aspect-ratio: 1.3/1.5;
  width: 100%;
  height: 100%;
}

.Coupon_Card .card-holder .img-holder .Coupon-img {
  width: 100%;
  height: 100%;
}

.Coupon_Card .card-holder .text-holder {
  margin-bottom: 6px;
}

.Coupon_Card .card-holder .text-holder .heading-holder h3 {
  text-align: left;
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 2px;
}

.Coupon_Card .card-holder .text-holder .code-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Coupon_Card .card-holder .text-holder .code-holder p {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 0px;
}

.Coupon_Card .card-holder .text-holder .code-holder .copy-icon {
  text-align: right;
  font-size: 12px;
  color: #ffffff;
}

.Coupon_Card .card-holder .border-right-holder {
  border-right: 1px dashed #ffffff;
}

.Coupon_Card .Coupon-discount-holder {
  position: absolute;
  top: 10px;
  left: -33px;
  width: 130px;
  rotate: -36deg;
}

.Coupon_Card .Coupon-discount-holder .text-holder h3 {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #ff0000;
  margin-bottom: 0px;
}

.Coupon_Card .Coupon-discount-holder .text-holder p {
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.Coupon_Card .Coupon-discount-holder .text-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
}

@media (min-width: 0px) and (max-width: 320px) {
 

  .Coupon_Card {
    height: 155px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Coupon_Card .card-holder .img-holder {
    aspect-ratio: 1.2/1;
  }

  .Coupon_Card {
    height: 155px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Coupon_Card .card-holder .img-holder {
    aspect-ratio: 1.2/1;
  }

  .Coupon_Card {
    height: 170px;
  }
}

@media (min-width: 578px) and (max-width: 767px) {
  .Coupon_Card .card-holder .img-holder {
    aspect-ratio: 1.3/1.2;
  }

  .Coupon_Card {
    height: 135px;
  }
}


@media (min-width: 768px) and (max-width: 991px) {
  .Coupon_Card .card-holder .img-holder {
    aspect-ratio: 1.3/1.2;
  }

  .Coupon_Card {
    height: 135px;
  }
}