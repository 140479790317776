.Edit_Address_Modal .form-section .account-form {
  width: 100%;
  height: 100%;
  box-shadow: none;
  padding: 0px;
  margin-top: 0px;
  border-radius: 0px;
}

.Edit_Address_Modal .modal-body h4 {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #060606;
  text-decoration: none;
}
