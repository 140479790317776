.Membership_card .card-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 29px #00000017;
  border-radius: 5px;
  padding: 20px 15px 0px 15px;
  transition: 0.3s;
}
.Membership_card:hover .card-holder {
  background: #fff7d7;
}
.Membership_card:hover{
  transform:scale(1.1);
  transition: 0.3s;
}
.Membership_card .card-holder .card-header .heading-holder h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #fece00;
}

.Membership_card .card {
  border: none;
}

.Membership_card .card-holder .card-header {
  background: none;
  border: none;
}


.Membership_card .card-holder .card-body {
  height: 100%;
  /* overflow: hidden; */
  overflow-y: auto;
  margin-bottom: 15px;
  aspect-ratio: 1/1;
}


.Membership_card .card-holder .card-body::-webkit-scrollbar {
  width: 5px;
}
.Membership_card .card-holder .card-body::-webkit-scrollbar:horizontal {
  height: 8px;
}
/* Track */
.Membership_card .card-holder .card-body::-webkit-scrollbar-track {
  background: #E8E8E8;
}
.Membership_card .card-holder .card-body::-webkit-scrollbar-track:horizontal {
  background: #E8E8E8;
}
/* Handle */
.Membership_card .card-holder .card-body::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}
 .Membership_card .card-holder .card-body::-webkit-scrollbar-thumb:horizontal{
   background: #c7c7c7;
  border-radius: 5px;
   }
.Membership_card .card-holder .card-footer {
  background: none;
  border: none;
  padding: 0px;
}

.Membership_card .card-holder .card-footer .btn-holder {
  text-align: center;
}

.Membership_card .card-holder .card-footer .btn-holder .View_More_Btn {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  background: #fece00;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #fece00;
}

.Membership_card .card-holder .card-footer .btn-holder .View_More_Btn:hover {
  background: #ffd864;
  border: 1px solid #ffd864;
}

.Membership_card .card-holder .card-body .list-holder ul {
  list-style-type: none;
  padding-left: 0px;
}

.Membership_card .card-holder .card-body .list-holder li {
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  margin-bottom: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.Membership_card .card-holder .card-body .list-holder .main-text {
  font-weight: 600;
}

.Membership_card .card-holder .card-body .list-holder .check-icon {
  color: #68cb65;
  border: 1px solid #68cb65;
  border-radius: 50px;
  width: 10px;
  height: 10px;
  padding: 4px;
  margin-right: 10px;
}

.Membership_card .card-holder .card-body .list-holder .cross-icon {
  color: #de4548;
  border: 1px solid #de4548;
  border-radius: 50px;
  width: 10px;
  height: 10px;
  padding: 4px;
  margin-right: 10px;
}

.Membership_card .card-holder .card-body .list-holder .star-icon {
  color: #faa700;
  border: 1px solid #faa700;
  border-radius: 50px;
  width: 10px;
  height: 10px;
  padding: 4px;
  margin-right: 10px;
}


/* 
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
} */
