.Call_support-sec .icon-div .supporticon{
    font-size: 25px;
    color: #fff;
}

.Call_support-sec .icon-div {
    background-color: #f88d28;
    min-height: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 30px;
    bottom: 40px;
    border-radius: 100px;
    z-index: 999999;
}