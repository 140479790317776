.counter-sec {
  margin-top: 5%;
  margin-bottom: 12%;
}

.counter-sec .bg-image {
  background-image: url("../../../../public/assets/Image/about/counter-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 4/1;
  border-radius: 10px;
  position: relative;
  padding: 66px 45px 56px 45px;
}

.counter-sec .imag-overlay {
  position: absolute;
  border-radius: 10px;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #000000e0, #0000007d);
}

.counter-sec .heading-holder h2 {
  font-size: 32px;
  font-weight: 500;
  color: #fff;
  position: relative;
}

.counter-sec .total-holder h4 {
  font-size: 30px;
  color: #fff;
  position: relative;
  color: #faa700;
}

.counter-sec .total-holder p {
  font-size: 16px;
  color: #fff;
  position: relative;
}

.counter-sec .border-line {
  border-right: 2px solid #fff;
  position: relative;
}

@media (min-width: 0px) and (max-width: 767px) {
  .counter-sec .border-line {
    border-right: none;
  }

  .counter-sec .heading-holder h2 {
    font-size: 18px;
  }

  .counter-sec .bg-image {
    padding: 25px 16px 16px 16px;
  }

  .counter-sec .total-holder h4 {
    font-size: 22px;
  }

  .counter-sec .total-holder p {
    font-size: 15px;
  }

  .counter-sec {
    margin-bottom: 35%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .counter-sec .heading-holder h2 {
    font-size: 18px;
  }

  .counter-sec .total-holder h4 {
    font-size: 22px;
  }

  .counter-sec .total-holder p {
    font-size: 15px;
  }

  .counter-sec {
    margin-bottom: 22%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .counter-sec .heading-holder h2 {
    font-size: 22px;
  }

  .counter-sec .total-holder h4 {
    font-size: 27px;
  }

  .counter-sec {
    margin-bottom: 18%;
  }
}
