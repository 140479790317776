.our-product {
  margin-bottom: 12%;
}

.our-product .view-all {
  margin-top: 2%;
}

.our-product .view-all p {
  width: fit-content;
}

.our-product .view-all {
  text-align: -webkit-right;
}

.our-product .our-product-slider {
  padding: 10px 0px;
}

.our-product .desktop_view_product_category_slider {
  display: block;
}

.our-product .mobile_view_product_category_slider {
  display: none;
}

.birthday-cardd {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  display: block;
  box-shadow: 0px 3px 3px #00000029;
  border: 0.5px solid #faa700;
  border-radius: 5px;
  height: 82%;
}

.birthday-cardd .cakeimg {
  aspect-ratio: 2/1.3;
  object-fit: cover;
  width: 100%;
}

.birthday-cardd .wishes-div{
  text-align: center;
}
.birthday-cardd .wishes-div .hbdd {
  font-size: 17px;
  background: #fff;
  padding: 5px 10px;
  margin: -15px auto 0px auto;
  z-index: 999;
  position: relative;
  width: fit-content;
  color:#faa700;
  font-weight: 700;
}
.birthday-cardd .wishes-div .wishes{
  font-size: 16px;
  margin-bottom: 0px;
  font-style: italic;
}

@media (min-width: 0px) and (max-width: 991px) {
  .our-product .product-image .text-holder p {
    font-size: 14px;
  }

  .our-product .desktop_view_product_category_slider {
    display: none;
  }

  .our-product .mobile_view_product_category_slider {
    display: block;
  }

}

@media (min-width: 0px) and (max-width: 575px) {
  .birthday-cardd {
    height: auto;
  }
}