.Newly_launch_modal .video-main .video-bg {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/0.4;
  object-fit: cover;
  background: #000;
}

.Newly_launch_modal .modal-body {
  padding: 0px;
  margin-bottom: -9px;
  background: #000;
}

.Newly_launch_modal .modal-content {
  background-color: transparent;
  border: 0px;
}

.Newly_launch_modal .modal-content .logo-holder {
  text-align: center;
  height: 50px;
  margin-bottom: 5px;
}

.Newly_launch_modal .modal-content .logo-holder .img-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Newly_launch_modal .modal-header {
  border-bottom: 0px;
  position: absolute;
  z-index: 999;
  right: 50px;
}

.Newly_launch_swiper .swiper-pagination-bullet-active {
  opacity: 1;
  background: #faa700 !important;
}

.Newly_launch_swiper .swiper-pagination-bullet {
  background: #ffffff;
  opacity: 1;
}

.Newly_launch_modal .modal-header .btn-close {
  filter: invert(1);
  opacity: 1;
}

.Newly_launch_swiper .swiper-button-prev:after,
.Newly_launch_swiper .swiper-button-next:after {
  font-size: 23px;
}

.Newly_launch_swiper .swiper-button-prev,
.Newly_launch_swiper .swiper-button-next {
  color: #ffffff;
  background: #000;
  padding: 22px;
  border-radius: 50%;
  background-size: 5px;
}

.video-main {
  position: relative;
}

.pip-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
}

.Newly_launch_modal .videomokeup .imfmok {
  width: 100%;
}

.Newly_launch_modal .videomokeup .allmodal {
  border: 40px solid #000;
  border-radius: 10px;
  outline: 1px solid #fff;
}

@media (min-width: 0px) and (max-width: 991px) {
  .Newly_launch_modal .swiper-button-next {
    display: none;
  }

  .Newly_launch_modal .swiper-button-prev {
    display: none;
  }

  .Newly_launch_modal .videomokeup .allmodal {
    border: 15px solid #000;
  }
}
