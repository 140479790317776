.Scanner_Modal .scanner-img-holder {
height: 400px;
}
 
.Scanner_Modal .scanner-img-holder .scanner-img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
}


.Scanner_Modal .modal-header .btn-close {
    margin: 0px;
}

.Scanner_Modal .modal-header {
    justify-content: space-between;
}

.Scanner_Modal .modal-header h3 {
    margin: 0px auto;
    font-weight: 700;
    color: #000;
}