.Delete_Address .btn-holder{
    display: flex;
}

.Delete_Address .btn-holder .SubmitBtn {
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: #000;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    padding: 8px 0px;
    margin: 0px 5px;
    width: 100%;
    border-radius: 50px;
}

