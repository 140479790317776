.changepassword-section .passwordmain {
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000000d;
  border-radius: 7px;
  padding: 20px 72px 3px 72px;
}

.changepassword-section .formpart sup {
  top: -20px !important;
}
.changepassword-section .submitbtn {
  width: 207px;
  height: 38px;
  border: none;
  background: #212121 0% 0% no-repeat padding-box;
 
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  color: #ffffff;
  opacity: 1;
  border-radius: 50px;
  margin: 70px 0px 20px 0px;
}

.changepassword-section .submitbtn:hover {
  background: #707070 0% 0% no-repeat padding-box;
}

.changepassword-section .formpart .form-control {
  font-size: 14px;
  background-color: #f6f6f6;
  border: 0px solid #ced4da;
  text-align: left;
  font-weight: 400;
  color: #a2a2a2;
  height: 40px;
  border-radius: 50px;
}

.changepassword-section .formpart .form-control:focus {
  box-shadow: none;
  background-color: #f6f6f6;
}

.changepassword-section .pass-heading h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
  color: #323232;
}

.changepassword-section .cancel {
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  color: #323232;
  border-radius: 50px;
}

.changepassword-section .input-group-text {
  border: none;
  background-color: #f6f6f6;
}

@media (min-width: 0px) and (max-width: 485px) {
  .changepassword-section .pass-heading h2 {
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 11px;
  }

  .changepassword-section .submitbtn {
    margin: 50px 0px 15px 0px;
  }

  .changepassword-section .passwordmain {
    padding: 20px 28px 3px 28px;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .changepassword-section .pass-heading h2 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 15px;
  }

  .changepassword-section .submitbtn {
    margin: 60px 0px 18px 0px;
  }
}
