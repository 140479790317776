.adresmain h3 {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
  padding-left: 25px;
  padding-bottom: 15px;
}

.adresmain .adresss {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 25px;
  opacity: 1;
  margin-bottom: 26px;
}

.adresmain .adresss .primary {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.adresmain .adresss .disha {
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.Choose_Payment_Option {
  background: #fffdf5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 13px 25px 0px 25px;
  margin-bottom: 40px;
}

.Choose_Payment_Option .payment_method_card .paycredit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Choose_Payment_Option .heading-holder h4 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #323232;
  margin-bottom: 20px;
}

.Choose_Payment_Option .radio-btn-input-holder {
  display: flex;
  /* margin-bottom: 1%; */
}

.Choose_Payment_Option .radio-btn-input-holder .form-check-label {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  margin-top: 5px;
}

.Choose_Payment_Option .radio-btn-input-holder input.form-check-input {
  font-size: 20px;
}

.Choose_Payment_Option .radio-btn-input-holder .form-check {
  margin-right: 5%;
}

.Choose_Payment_Option .payment_method_card {
  padding: 30px 0px;
}

.Choose_Payment_Option .bodr_bottom {
  border-bottom: 1px solid #a7a1a1;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input {
  display: flex;
  justify-content: normal;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 30px;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .form-label {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  width: 25%;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .form-control {
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  color: #323232;
  margin-left: 10px;
}

.Choose_Payment_Option .payment_method_card .flex-radio-input .payment-logo {
  width: 60px;
  height: 30px;
  object-fit: contain;
}

.Choose_Payment_Option
  .payment_method_card
  .flex-radio-input
  .payment-logo-holder {
  margin-left: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #b2b2b2;
  border-radius: 5px;
  padding: 4px 22px;
}

.payment_method_card .flex-radio-input input.form-check-input {
  font-size: 20px;
}

@media (min-width: 280px) and (max-width: 320px) {
  .Choose_Payment_Option .payment_method_card {
    margin-bottom: 5%;
  }

  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }

  .Choose_Payment_Option .payment_method_card {
    padding: 14px 15px;
  }

  .adresmain h3 {
    font-size: 18px;
    padding-left: 0px;
    padding-bottom: 5px;
  }
  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Choose_Payment_Option .payment_method_card {
    margin-bottom: 5%;
  }

  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }

  .Choose_Payment_Option .payment_method_card {
    padding: 14px 15px;
  }
  .adresmain h3 {
    font-size: 18px;
    padding-left: 0px;
    padding-bottom: 5px;
  }
  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 12px;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .Choose_Payment_Option .payment_method_card {
    margin-bottom: 5%;
  }

  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }
  .Choose_Payment_Option .payment_method_card {
    padding: 14px 15px;
  }
  .adresmain h3 {
    font-size: 19px;
    padding-left: 0px;
    padding-bottom: 5px;
  }
  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 16px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 13px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .Choose_Payment_Option .payment_method_card {
    margin-bottom: 5%;
  }

  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }
  .adresmain h3 {
    font-size: 19px;
    padding-left: 0px;
    padding-bottom: 5px;
  }
  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 16px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Choose_Payment_Option .heading-holder h4 {
    font-size: 15px;
  }
  .adresmain h3 {
    font-size: 20px;
    padding-left: 0px;
    padding-bottom: 5px;
  }
  .adresmain .adresss .primary {
    margin-bottom: 5px;
    font-size: 17px;
  }

  .adresmain .adresss .disha {
    margin-bottom: 5px;
    font-size: 14px;
  }
}
