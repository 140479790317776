.LogIn .bg-color {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.LogIn .log_in_form .login-with-option-holder {
  display: flex;
}

.LogIn .log_in_form .login-with-option-holder .login-with-heading{
  margin-right: 25px;
}

.LogIn .log_in_form .login-with-option-holder .form-label {
  font-weight: 600;
  font-size: 15px;
  text-decoration: underline;
}

.LogIn .terms-and-conditions-checkbox {
  display: flex;
}

.LogIn .login-btn-holder .SubmitBtn {
  padding: 8px 45px;
  width: fit-content;
  margin: 0px 4px;
}

.LogIn a {
  color: #000;
  text-decoration: underline;
}

.LogIn .log_in_form  .password_div{
  position: relative;
}


.LogIn .log_in_form  .password_div .send-otp-text-holder {
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  background: #198754;
  color: #fff;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.LogIn .log_in_form  .password_div .send-otp-text-holder:hover {
  background: #3db97f;
}

.LogIn .log_in_form .login-with-option-holder  .form-check-label {
  font-size: 15px;
  font-weight: 500;
}

.LogIn .log_in_form .login-with-option-holder .form-check-input:checked {
  background-color: #000000;
  border-color: #000000;
}

.LogIn .log_in_form .login-with-option-holder .form-check-input:focus {
  border-color: #000;
  box-shadow: none;
}


.LogIn .img-section {
  background-color: #faa700;
  height: 100vh;
  /* padding: 25% 0%; */
  display: flex;
  align-items: center;
  position: relative;
}

.LogIn .img-section .img-holder {
  position: absolute;
  left: -25%;
}

.LogIn .img-section .img-holder .log_in_img {
  width: 100%;
  height: 311px;
  object-fit: contain;
}

/*LogIn form css start*/
.LogIn .bg-color .back_to_span p {
  font-size: 16px;
  font-weight: 500;
  color: black;
  text-align: center;
}
.lbbbb{
  font-size: 14px;
}
.LogIn .bg-color .heading-holder {
  margin-top: 20%;
}

.LogIn .bg-color .heading-holder h4 {
  font-size: 25px;
  font-weight: 600;
  color: #2b2b2b;
  text-align: center;
}

.LogIn .bg-color .heading-holder p {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  color: #949494;
}

.LogIn .bg-color .log_in_form {
  margin-top: 5%;
}
.LogIn .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.LogIn .bg-color .log_in_form .form-control {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  color: #000000;
  height: 40px;
}

.LogIn .bg-color .log_in_form .form-control::placeholder {
  font-size: 14px;
  color: #a2a2a2;
}

.LogIn .bg-color .log_in_form .form-control:focus {
  box-shadow: none;
}

.LogIn .bg-color .log_in_form .Forgot_password {
  font-size: 16px;
  font-weight: 500;
  color: #323232;
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.LogIn .bg-color .log_in_form .btn-holder {
  text-align: center;
  margin-top: 10%;
}
.LogIn .password_div .otp-verification-icon {
  position: absolute;
  top: 6px;
  right: 10px;
}

/* .LogIn .bg-color .log_in_form .btn-holder .submit_btn {
  background: #212121 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: #000;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 76px;
} */

.LogIn .bg-color .log_in_form .create_account {
  margin-top: 3%;
}

.LogIn .bg-color .log_in_form .create_account p {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #323232;
  cursor: pointer;
}

.LogIn .bg-color .log_in_form .create_account .cancel-text {
  text-align: center;
  text-decoration: underline;
  font-size: 15px;
  display: flex;
  font-weight: 400;
  color: #323232;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.LogIn .bg-color .log_in_form .create_account .login_text p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #323232;
}

.LogIn .bg-color .log_in_form .create_account .login_text span {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  cursor: pointer;
}

.LogIn .bg-color .log_in_form .log_in_with .log_in_with {
  margin-top: 10%;
}

.LogIn .bg-color .log_in_form .log_in_with p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #979797;
  display: flex;
}

.LogIn .bg-color .log_in_form .log_in_with p:before,
.LogIn .bg-color .log_in_form .log_in_with p:after {
  color: white;
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
  margin: auto 5px;
}

.LogIn .bg-color .log_in_form .social_img_holder {
  text-align: center;
}

.LogIn .bg-color .log_in_form .social_img_holder .social_img {
  height: 30px;
}

.LogIn .bg-color .log_in_form .password-field-holder {
  position: relative;
}

.LogIn .bg-color .log_in_form .eye-icon-holder {
  position: absolute;
  top: 0;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.LogIn .bg-color .log_in_form .eye-icon {
  font-size: 15px;
  color: #6c757d;
}

/*LogIn form css start*/
@media (min-width: 280px) and (max-width: 320px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .img-section {
    height: 50vh;
    padding: 0px 0%;
  }

  .LogIn .img-section .img-holder {
    left: 0;
    right: 0;
  }

  .LogIn .img-section .img-holder .log_in_img {
    height: 200px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .img-section {
    height: 50vh;
    padding: 0px 0%;
  }

  .LogIn .img-section .img-holder {
    left: 0;
    right: 0;
  }

  .LogIn .img-section .img-holder .log_in_img {
    height: 200px;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .img-section {
    height: 50vh;
    padding: 0px 0%;
  }

  .LogIn .img-section .img-holder {
    left: 0;
    right: 0;
  }

  .LogIn .img-section .img-holder .log_in_img {
    height: 200px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .heading-holder {
    margin-top: 5%;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .img-section {
    height: 50vh;
    padding: 0px 0%;
  }

  .LogIn .img-section .img-holder {
    left: 0;
    right: 0;
  }

  .LogIn .img-section .img-holder .log_in_img {
    height: 200px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .LogIn .bg-color .back_to_span p {
    margin-top: 5%;
  }

  .LogIn .bg-color .heading-holder h4 {
    font-size: 20px;
  }

  .LogIn .bg-color .log_in_form .social_img_holder {
    margin-bottom: 5%;
  }

  .LogIn .img-section .img-holder {
    left: -21%;
  }
}


@media (min-width:0px) and (max-width:767.98px){
  .LogIn .img-section {
    display: none;
}
.LogIn .log_in_form .login-with-option-holder {
  display: block;
}
}