.Return_Reason_Modal .preview-holder {
  margin: 5px 0px;
}

.Return_Reason_Modal .img-preview-holder .img-preview {
  width: 100%;
  height: 100%;
}

.Return_Reason_Modal .img-preview-holder {
    aspect-ratio: 1/1;
    padding: 5px;
    border: 1px dashed #000;
    margin: 0px auto;
}