.SuccessModalPop .tryagainbtn {
  background-color: green;
  color: #fff;
  border-radius: 20px;
  border: 0;
  padding: 5px 53px;
  margin-top: 4%;
}

.SuccessModalPop .modalHead {
  text-align: center;
  color: green;
  margin-top: 20px;
}

.SuccessModalPop .modal-content {
  border: 0;
  box-shadow: 0px 3px 14px #00000012;
}
