.congratsModal .modal-body img {
    display: block;
    margin: 0 auto 20px;
}

.congratsModal .modal-body h4 {
    font-size: 1.5rem;
    font-weight: bold;
}

.congratsModal .modal-body p {
    font-size: 1rem;
    color: #333;
}

.congratsModal .alert {
    font-size: 0.9rem;
    color: #333;    
    margin-bottom: 20px;
}

.congratsModal .dismissbtn {
    background-color: #0F3768;
    border: 0;
}