.main-product-img-main .card-second-line {
  font-size: 15px;
  color: #df4223;
  text-align: left;
  margin-bottom: 5px;
  font-weight: 500;
  margin-top: 5%;
  cursor: pointer;
}

.main-product-img-main {
  text-align: right;
  height: auto;
}

.main-product-img-main .card {
  border: none;
  /* box-shadow: 0px 3px 3px #00000029; */
  background: #f6f6f6;
  position: relative;
}


.main-product-img-main .card .Coupon-discount-holder {
  position: absolute;
  top: 16px;
  left: -40px;
  width: 130px;
  rotate: -36deg;
}

.main-product-img-main .card .Coupon-discount-holder .text-holder h3 {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: #ff0000;
  margin-bottom: 0px;
  cursor: pointer;
}

.main-product-img-main .card .Coupon-discount-holder .text-holder p {
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.main-product-img-main .card .Coupon-discount-holder .text-holder {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
}




.main-product-img-main .card .Coupon-latest-holder {
  position: absolute;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  width: fit-content;
  top: 0%;
  right: 0;
  /* background: #c0f6ea; */
}

.main-product-img-main .card .Coupon-latest-holder .text-holder h3 {
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0px;
  /* background: #ffce00; */
  /* margin-top: 7px; */
  /* padding: 1px 5px; */
  cursor: pointer;
}

.main-product-img-main .card .Coupon-latest-holder .text-holder p {
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 0px;
}

.main-product-img-main .card .Coupon-latest-holder .text-holder {
  padding: 0px 10px;
  background: #ffce00;
  margin-top: 10px;
}



.main-product-img-main .text-holder {
  text-align: left;
}

.main-product-img-main .text-holder h4 {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.main-product-img-main .text-holder span {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  color: #333333;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.main-product-img-main .card .card-body {
  background: #fff;
  padding-top: 0px;
}

.main-product-img-main {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  display: block;
  box-shadow: 0px 3px 3px #00000029;
  border: 0.5px solid #faa700;
  border-radius: 5px;
}

.main-product-img-main:hover {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  display: block;
  /* border: 0.5px solid #FAA700; */
  border-radius: 5px;
}

.main-product-img-main .main-product-main {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain !important;
  aspect-ratio: 1/1;
  background-position: center;
  cursor: pointer;
}

.main-product-img-main .main-product-main .product-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1/1;
  transition: transform 0.5s;
  object-position: top;
}

/* .main-product-img-main:hover .product-img {
  transform: scale(1.3);
} */

.main-product-img-main .card-footer {
  border-top: none;
  background-color: #fff;
}

.main-product-img-main .add-cart-sec .cart-logo.active {
  color: #df4223;
}

.main-product-img-main .add-cart-sec .cart-logo {
  color: #faa700;
}

.main-product-img-main .add-cart-sec p {
  font-size: 12px;
  color: #000;
  font-weight: 500;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.main-product-img-main .add-cart-sec {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 5px 8px;
}

.product_table .tabless .qnty .trash-icon-holder .prices {
  display: flex;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .main-product-img-main .card-second-line {
    font-size: 14px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .main-product-img-main:hover .add-cart-sec {
    bottom: 100px;
    left: 99px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .main-product-img-main:hover .add-cart-sec {
    left: 135px;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .main-product-img-main:hover .orange-bg-card {
    left: 40%;
  }

  .main-product-img-main .card-second-line {
    font-size: 12px;
  }

  .main-product-img-main .text-holder span {
    font-size: 10px;
  }

  .main-product-img-main .text-holder h4 {
    font-size: 12px;
  }

  .main-product-img-main .card .card-body {
    padding-left: 10px;
  }
}