.wallet .amount-details p {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
}

.wallet .btn-recharge {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
    width: max-content;
    color: #000;
}

.wallet .btn-recharge:hover {
    background: #ccc 0% 0% no-repeat padding-box;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
    width: max-content;
    color: #000;
}

.withrow_request .close-btn{
background: #ccc;
border: 1px solid #ccc;
color: #000;
font-size: 15px;
font-weight: 500;
}

.withrow_request .close-btn:hover{
    background: #ccc;
    border: 1px solid #ccc;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    }

.withrow_request .save-btn{
    background: #faa700;
    border: 1px solid #faa700;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    }

    .withrow_request .save-btn:hover{
        background: #faa700;
        border: 1px solid #faa700;
        color: #000;
        font-size: 15px;
        font-weight: 500;
        }

    

            .withrow_request .modal-body{
                box-shadow: none;
                     }

.wallet .delete-img {
    width: 21px;
    height: 17px;
}

.wallet .wallet-table thead {
    background: #9B9B9B 0% 0% no-repeat padding-box;

}

.wallet .wallet-table th {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
    padding: 12px;
}

.wallet .wallet-table td {
    border-bottom: 0.5px dashed #707070;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
    padding: 12px;
}

@media(max-width:767px) {
    .wallet .amount-details p {
        font-size: 12px;

    }

    .wallet .btn-recharge {
        font-size: 10px;
        width: 80px;

    }

    .wallet .wallet-table th {
        font-size: 12px;
        padding: 6px;
    }

    .wallet .wallet-table td {
        font-size: 11px;
        padding: 8px;
    }
}

@media(min-width:0px) and (max-width:359px) {
    .wallet .transaction-heading {
        width: 150px;
    }
}