.Membership__Modal_card {
}


.Membership__Modal_card .heading-holder{
    margin: 15px 0px;
}

.Membership__Modal_card .heading-holder h3 {
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.Membership_card_modal  .Membership_card:hover {
  transform: scale(1);
  transition: 0.3s;
}
.Membership_card_modal  .Membership_card:hover {
  transform: scale(1);
  transition: 0.3s;
}
.Membership_card_modal  .Membership_card:hover .card-holder {
  background: #fff;
}