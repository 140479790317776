@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap");

.wallet {
  padding-bottom: 5%;
  margin-bottom: 120px;
}

.wallet nav .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: url("../../../public/assets/Image/Icon/arrow-forward.png");
}

.wallet .breadcrumb .breadcrumb-item a {
  font-size: 13px;
  font-weight: 600;
  color: #0f3768;
}

.wallet .wallet-coupon-card-holder {
  height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.wallet .breadcrumb .breadcrumb-item {
  font-size: 13px;
  font-weight: 600;
  color: #0f3768;
}

.wallet .breadcrumb .breadcrumb-item.active {
  color: #000;
  font-weight: 500;
}

.wallet .start-wallet-coupen .input-group .form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  height: 37px;
  font-family: "Poppins";
  border-right: none;
}

.wallet .start-wallet-coupen .input-group .form-control:focus {
  box-shadow: none;
}

.wallet .start-wallet-coupen .input-group .input-group-text {
  background: #faa700;
  border-radius: 0px 5px 5px 0px;
  color: #fff;
  border: none;
  width: 87px;
  text-align: center;
  font-family: "Poppins";
}

.wallet .start-wallet-coupen .apply-coupen h4 {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins";
}

.wallet .start-wallet-coupen .apply-coupen p {
  font-size: 14px;
  font-family: "Poppins";
}

.wallet a {
  text-decoration: none;
}

.wallet .coupon-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 15px;
}

.wallet .coupon-div .input-coupon {
  font-size: 14px;
  text-align: center;
  color: #000;
  padding: 8px;
  width: 71%;
  border: 1px dashed #707070;
}

.wallet .coupon-div .coupon-img {
  float: right;
  cursor: pointer;
}

.wallet .coupon-div .flat-p {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin-top: 10px;
}

.wallet .coupon-div .plat-p-subtext {
  font-weight: normal;
  font-size: 14px;
  color: #00000069;
  margin-bottom: 0px;
}

.wallet .coupon-div .btn-apply {
  background: #9b9b9b;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
}

/***********wallet payment*******/
.wallet .wallet-payment .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #00000029;
  border-radius: 29px;
  border: none;
}

.wallet .payment-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 25px;
}

.wallet .wallet-payment .card .card-body {
  padding: 34px 74px 34px 74px;
}

.wallet .payment-div h5 {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #707070;
  width: 31%;
}

.wallet .wallet-payment .amount h6 {
  font-size: 14px;
  font-family: "Poppins";
  margin-bottom: 3%;
}

.wallet .wallet-payment .rupees p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 3%;
}

.wallet .payment-mode .imag-card .card-img {
  width: 33px;
  height: 28px;
}

.wallet .payment-mode .card-name {
  font-size: 14px;
  margin-bottom: 0%;
}

.wallet .payment-mode {
  background: #ffffff;
  box-shadow: 0px 0px 8px #00000029;
  border-radius: 5px;
  padding: 7px;
}

.wallet .payment-mode .imag-card .pay-img {
  width: 50px;
  height: 34px;
}

.wallet .payment-mode .imag-card .upi-img {
  width: 43px;
  height: 15px;
}

.wallet .payment-mode .imag-card .debit-img {
  width: 34px;
  height: 27px;
}

.wallet .payment-mode .imag-card .phonepay-img {
  width: 28px;
  height: 28px;
}

.wallet .btn-proceed {
  background: #faa700;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: 98px;
}

.wallet .btn-proceed:hover {
  background: #faa700;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: 98px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .wallet .payment-div h5 {
    width: 39%;
  }
}

@media (min-width: 999px) and (max-width: 1200px) {
  .wallet .wallet-payment .card .card-body {
    padding: 34px 39px 34px 39px;
  }

  .wallet .payment-div h5 {
    width: 38%;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .wallet .wallet-payment .card .card-body {
    padding: 34px 39px 34px 39px;
  }

  .wallet .payment-mode .card-name {
    font-size: 12px;
  }

  .wallet .payment-div h5 {
    width: 38%;
  }
}

/********modal start**********/
.procced-modal .modal {
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.6);
  transform: translateY(77px);
}

.procced-modal .modal .modal-content {
  background-color: transparent;
  border: none;
}

.procced-modal .modal .successfully .btn-success1 {
  background: #faa700;
  border-radius: 25px;
  width: 327px;
  height: 47px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.desk-top-view-wallet .btn-history {
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 12px;
  font-family: "Poppins";
  color: #000;
  font-weight: 600;
}

.desk-top-view-wallet .btn-history:hover {
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 12px;
  font-family: "Poppins";
  color: #000;
  background: #ccc;
  font-weight: 600;
}

@media (max-width: 767px) {
  .wallet .start-wallet-coupen .input-group .form-control {
    height: 30px;
    font-size: 12px;
  }

  .wallet .start-wallet-coupen .input-group .input-group-text {
    height: 30px;
    font-size: 12px;
  }

  .wallet .start-wallet-coupen .apply-coupen h4 {
    font-size: 14px;
  }

  .wallet .start-wallet-coupen .apply-coupen p {
    font-size: 12px;
  }

  .wallet .coupon-div .input-coupon {
    font-size: 10px;
    text-align: center;
    color: #000;
    padding: 5px;
    width: 70px;
    /* height: 20px; */
    border: 1px dashed #707070;
  }

  .wallet .coupon-div .btn-apply {
    background: #9b9b9b;
    border-radius: 6px;
    color: #fff;
    font-size: 10px;
    /* width: 60px;  */
    /* height: 20px; */
  }

  .wallet .coupon-div .flat-p {
    font-size: 10px;
    margin-top: 10px;
  }

  .wallet .coupon-div .plat-p-subtext {
    font-size: 9px;
  }

  .wallet .coupon-div {
    padding: 10px;
  }

  .wallet .payment-div h5 {
    font-size: 14px;
    border-bottom: none;
    margin-bottom: 0%;
    width: auto;
  }

  .wallet .border-payment {
    border-bottom: 1px solid #707070a6;
    width: 116px;
  }

  .wallet .payment-div {
    padding: 15px;
  }

  .wallet .wallet-payment .card {
    border-radius: 10px;
  }

  .wallet .wallet-payment .card .card-body {
    padding: 20px 10px 18px 10px;
  }

  .wallet .wallet-payment .amount h6 {
    font-size: 12px;
  }

  .wallet .wallet-payment .rupees p {
    font-size: 12px;
  }

  .wallet .payment-mode .imag-card .debit-img {
    width: 30px;
    height: 22px;
  }

  .wallet .payment-mode .imag-card .phonepay-img {
    width: 23px;
    height: 23px;
  }

  .wallet .imag-card .upi-img {
    width: 35px;
    height: 12px;
  }

  .wallet .payment-mode .imag-card .pay-img {
    width: 50px;
    height: 30px;
  }

  .wallet .payment-mode .imag-card .card-img {
    width: 27px;
    height: 23px;
  }

  .wallet .payment-mode .card-name {
    font-size: 12px;
  }

  .wallet .payment-mode1 .card-name {
    font-size: 10px;
  }

  .wallet .payment-mode1 {
    width: 87px;
    background: #ffffff;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
    padding: 7px;
  }

  .wallet .btn-proceed {
    font-size: 12px;
    width: 81px;
  }

  .procced-modal .modal .successfully .btn-success1 {
    width: 200px;
    height: 40px;
    color: #fff;
    font-size: 12px;
  }

  .procced-modal .modal {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.6);
    transform: translateY(0px);
  }
}

@media (min-width: 360px) and (max-width: 421px) {
  .wallet .payment-mode1 {
    width: 87px;
    padding: 5px;
  }

  .wallet .payment-mode {
    padding: 5px;
  }
}

@media (min-width: 0px) and (max-width: 359px) {
  .wallet .payment-mode1 {
    width: 63px;
    padding: 5px;
  }

  .wallet .payment-mode .card-name {
    font-size: 8px;
  }

  .wallet .coupon-div {
    padding: 5px;
  }

  .wallet .coupon-div .input-coupon {
    font-size: 8px;
    padding: 5px;
    width: 62px;
  }

  .wallet .coupon-div .btn-apply {
    font-size: 9px;
  }
}
