.product-banner {
  background: #f3f5f7 0% 0% no-repeat padding-box;
  /* padding: 46px 0px; */
  position: relative;
}

.product-banner .circle-holder {
  border: 4px solid #faa700;
  border-radius: 50%;
  max-width: 100px;
  height: 100px;
}
.product-banner .circle-holder .ecartlogo {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.product-banner .text-img-holder {
  display: flex;
  width: 100%;
}

.product-banner .text-img-holder .img-holder {
  margin-left: 5%;
}

.product-banner  .img-holder .img-shop {
  width: 100%;
  height: 100%;
  /* height: 395px; */
  /* aspect-ratio: 1/0.4; */
  object-fit: cover;
  border-radius: 7px;
}

.product-banner  .overflow-content{
  /* position: absolute;
  top: 50%;
  left: 0%;
  width: 100%; */
  margin-top: 15px;
}
.product-banner .name-holder {
  margin-top: 32px;
  margin-left: 40px;
}

.product-banner .name-holder h1 {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}

.product-banner .name-holder p{
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

@media (min-width: 0px) and (max-width: 767px) {
  .product-banner .name-holder {
    margin-left: 15px;
    margin-top: 28px;
  }

  .product-banner .name-holder h1 {
    font-size: 21px;
  }

  .product-banner .circle-holder {
    width: 80px;
    height: 80px;
  }

  .product-banner .circle-holder .ecartlogo {
    width: 72px;
    height: 72px;
  }

  /* .product-banner .text-img-holder {
 flex-wrap: wrap;
  } */
}
