/* Shop-By-Seller Css Started */

.Shop-By-Seller {
  margin-top: 3%;
  margin-bottom: 4%;
}

.Shop-By-Seller .Seller-section {
  margin-top: 5%;
}

.Shop-By-Seller .main-section {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  aspect-ratio: 2/2;
}

.Shop-By-Seller .main-section .seller-img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  transition: 0.3s;
  object-fit: cover;
  object-position: top;
}

.Shop-By-Seller .main-section:hover .seller-img {
  transform: scale(1.1);
  transition: 0.3s;
}

.Shop-By-Seller .main-section .bg-overlay {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  background: transparent linear-gradient(180deg, #0f376800 0%, #031a36d2 58%, #00142b 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.Shop-By-Seller .main-section .text-holder {
  position: absolute;
  bottom: 0px;
  left: 24px;
  z-index: 1;
}

.Shop-By-Seller .main-section .text-holder .local-text {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

/* 
.Shop-By-Seller .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #df4223;
}

.Shop-By-Seller .swiper-pagination-bullet {
  background: #df4223;
  opacity: 1;
} */

/* Shop-By-Seller Css End */

.Shop-By-Seller .Seller-section .silder-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
}

.Shop-By-Seller .Seller-section .swiper-button-prev,
.Shop-By-Seller .Seller-section .swiper-button-next {
  display: none;
}

.Shop-By-Seller .Seller-section .silder-btn .back-btn {
  content: url("../../../../public/assets/Image/Homepage/Popular-Categories/left-1.png");
  width: 24px;
  height: 24px;
  /* margin: 10px; */
  cursor: pointer;
  position: absolute;
  bottom: 185px;
  z-index: 999;
  left: 0px;
  filter: hue-rotate(45deg);
  filter: brightness(1);
}

.Shop-By-Seller .Seller-section .silder-btn .next-btn {
  content: url("../../../../public/assets/Image/Homepage/Popular-Categories/right-1.png");
  width: 24px;
  height: 24px;
  /* margin: 10px; */
  cursor: pointer;
  position: absolute;
  bottom: 185px;
  z-index: 999;
  right: 0px;
  filter: hue-rotate(45deg);
  filter: brightness(1);
}

@media (min-width: 0px) and (max-width: 991px) {
  .Shop-By-Seller .main-section .text-holder .local-text {
    font-size: 12px;
  }

  .Shop-By-Seller .main-section .text-holder {
    left: 5px;
  }
}