.second-header-sec {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1024;
}

.second-header-sec .headlogo {
  width: auto;
  height: 40px;
}

.second-header-sec {
  background: linear-gradient(200deg, #d1fff9, #a2e6d1) !important;
  box-shadow: 0px 3px 6px #00000029;
}

.second-header-sec .navbar .navbar-collapse .all-icon .cart-item-count-text{
  border-radius: 50%!important;
  background: #faa700;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #000;
}

.second-header-sec .navbar-nav .nav-link {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 15px;
  margin: 12px 11px 0px 11px;
}

/* .second-header-sec .navbar-nav .nav-link:hover {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 15px;
  margin: 25px 11px 0px 11px;
} */

.second-header-sec .header-icon-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.second-header-sec .navbar-nav .nav-link.active {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin: 12px 11px 0px 11px;
  padding-bottom: 15px;
  border-bottom: 3px solid #faa700;
}

.second-header-sec .searchbar-holder-main {
  position: relative;
}



.second-header-sec .searchbar-holder-main .list-group {
  position: absolute;
  width: 95%;
}

.second-header-sec .searchbar-holder span#basic-addon2 {
  background: #faa700;
  color: #fff;
}

/* .second-header-sec .navbar-nav .nav-link.show {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin: 25px 11px 0px 11px;
  padding-bottom: 15px;
} */

/* .second-header-sec .navbar-nav .dropdown {
  background: #df4223 0% 0% no-repeat padding-box;
  width: 352px;
} */

.second-header-sec .navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.second-header-sec .dropdown-toggle.show::after {
  font-size: 20px;
  vertical-align: 0;
  transform: rotate(180deg);
}

.second-header-sec .dropdown-toggle::after {
  font-size: 20px;
  vertical-align: 0;
}

.second-header-sec .dropdown-toggle::after {
  position: absolute;
  right: -7px;
  margin-top: 6px;
}

.second-header-sec .cart-logo {
  color: #000;
  /* margin: 10px 0px 0px 26px; */
  cursor: pointer;
  font-size: 18px;
}

.second-header-sec .navbar-toggler:focus {
  box-shadow: none;
}

.second-header-sec .dropdown-menu {
  background-color:rgb(255 167 0);
  border-radius: 0%;
  width: 352px;
  padding-top: 0%;
  padding-bottom: 0%;
  z-index: 1;
}

.second-header-sec .dropdown-item:hover {
  background: #faa700 0% 0% no-repeat padding-box;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  transition: all 0.4s ease-in-out;
}

.second-header-sec .dropdown-item:active {
  background: #df4223 0% 0% no-repeat padding-box;
  color: #fff;
  font-size: 15px;
  padding: 10px;
}

.second-header-sec .dropdown-item {
  color: #fff;
  font-size: 15px;
  padding: 10px;
}

.second-header-sec .dropdown-item:hover {
 background: #000;
}

.second-header-sec .login {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-top: 0px;
  text-decoration: underline !important;
}

.second-header-sec .dropdown-menu[data-bs-popper] {
  top: 99%;
  left: 0;
  margin-top: 0;
  height: 220px;
  overflow-y: auto;
}

.second-header-sec .all-icon {
  position: relative;
}

.second-header-sec .input-group-box {
  position: absolute;
  top: 51px;
  width: 425px;
  z-index: 1;
  left: -163%;
}

.second-header-sec .input-group-box .form-control {
  border-radius: 0%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #707070;
  border-radius: 19px;
  font-size: 13px;
}

.second-header-sec .navbar .navbar-nav {
  margin: 0;
}

.second-header-sec .navbar .navbar-collapse .all-icon {
  display: flex;
  width: 60%;
}

.second-header-sec .navbar-expand-lg .navbar-collapse {
  justify-content: space-around;
}

.second-header-sec .navbar .searchinpuuhead {
  position: relative;
}

.second-header-sec .navbar .all-icon .searchinpuuhead .form-control:focus {
  box-shadow: none;
}

.second-header-sec .navbar .searchinpuuhead .btnserch {
  position: absolute;
  background-color: #faa700;
  color: #fff;
  font-size: 20px;
  border-radius: 5px;
  right: 0px;
  top: 1px;
  padding: 8px 10px;
}

.ginanimationimg {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  right: 75%;
  /* -o-animation: cssAnimation 0s ease-in 10s forwards; */
  /* Opera */
  animation: cssAnimation 0s ease-in 50s forwards;
}


.slide-in-elliptic-top-fwd {
  animation: slide-in-elliptic-top-fwd 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@keyframes slide-in-elliptic-top-fwd {
  0% {
    transform: translateY(500px) rotateX(200deg) scale(0);
    transform-origin: 00% 00%;
    opacity: 0;
  }

  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 0% 1400px;
    opacity: 1;
    visibility:hidden;
    animation: cssAnimation 0s ease-in 15s forwards;
  }
}



.chiragdeskk {
  width: auto;
  height: 200px;
  position: absolute;
  left: -30%;
  top: 500px;
  
}


/* @keyframes chiragdeskk {
  0% {
    transform: translateY(500px) rotateX(200deg) scale(0);
    transform-origin: 00% 00%;
    opacity: 0;
   
  }

  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 1400px;
    opacity: 1;
    visibility:hidden;
    animation: cssAnimation 0s ease-in 15s forwards;   

  }
} */

/* @keyframes hideimageee {
  0% {
    transform: translateY(00px) rotateX(00deg) scale(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 0% 00px;
    opacity: 1;
  }
} */

@keyframes hideAfter5s {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

.chiragdeskk {
  animation: hideimageee 5s ease-in, hideAfter5s 0s ease-in 5s forwards;
  visibility: visible; /* Ensure it's visible initially */
}


@media (min-width: 1200px) and (max-width: 1400px) {
  .second-header-sec .navbar-nav .nav-link {
    color: #000;
    font-size: 14px;
    margin: 25px 11px 0px 11px;
  }

  /* .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 14px;
    margin: 25px 11px 0px 11px;
  } */

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 14px;
    margin: 25px 11px 0px 11px;
  }

  /* .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 14px;
    margin: 25px 11px 0px 11px;
  } */

  .second-header-sec .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0%;
    text-wrap: nowrap;
  }
}

.searchbar-holder{
  display: block;
}
.mobsearchhed{
  display: none;
}

@media (min-width: 0px) and (max-width: 991px) {
  .searchbar-holder{
    display: none !important;
  }
  .mobsearchhed{
    display: block;
  }
  .ginanimationimg {
    top: 50%;
    right: 6%;
}

  .second-header-sec .desktop-view {
    display: none;
  }

  .second-header-sec .header-icon-holder {
    justify-content: start;
    margin-top: 15px;
  }

  .second-header-sec .login {
    font-size: 18px;
    color: #000;
    padding-left: 10px;
    font-weight: 600;
    margin-top: 0px;
    text-decoration: underline;
  }

  .second-header-sec .mobile-view {
    display: block;
  }

  .second-header-sec .navbar-toggler {
    color: #000;
    background-color: #fff !important;
    margin-top: 8px;
    padding: 2px 10px;
  }

  .second-header-sec .navbar-nav .dropdown {
    margin-top: 10px;
  }

  .second-header-sec .navbar-nav .nav-link {
    color: #000;
    font-size: 13px;
    margin: 4px 16px 0px 10px;
  }

  /* .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 13px;
    margin: 4px 16px 0px 10px;
  } */

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 13px;
    border-bottom: none;
    margin: 4px 16px 0px 10px;
  }

  /* .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 13px;
    border-bottom: none;
    margin: 4px 16px 0px 10px;
  } */

  /* .second-header-sec .navbar-nav .dropdown {
    width: 298px;
  } */
  .second-header-sec .dropdown-toggle::after {
    position: absolute;
    right: inherit;
    margin-top: 6px;
  }

  .second-header-sec .dropdown-menu {
    width: 298px;
  }

  .second-header-sec .dropdown-item:hover {
    background: #df4223 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 12px;
    padding: 10px;
  }

  .second-header-sec .dropdown-item:active {
    background: #df4223 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 12px;
    padding: 10px;
  }

  .second-header-sec .dropdown-item {
    color: #fff;
    font-size: 12px;
    padding: 10px;
  }

  .top-header .search .form-control {
    font-size: 13px;
  }

  .top-header .search .search-btn {
    font-size: 13px;
    width: 84px;
  }

  .top-header .headlogo {
    width: 96px;
    height: auto;
  }

  .second-header-sec .cart-logo {
    margin: 5px 10px;
  }

  .top-header {
    background-color: #fff;
    padding: 10px 0px;
  }

  .top-header .search {
    margin-top: 15px;
  }

  .second-header-sec .input-group-box {
    position: inherit;
    width: 100%;
    left: 0px;
    top: 8px;
  }

  .second-header-sec {
    background: #abf3ef 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px 0px;
  }

}

@media (min-width: 992px) and (max-width: 1023px) {
  .second-header-sec .headlogo {
    height: 20px;
  }

  .second-header-sec .login {
    padding-left: 3px;
    /* margin-top: 6px; */
  }

  .second-header-sec .navbar-nav .nav-link {
    color: #000;
    font-size: 13px;
    margin: 12px 7px 0px 7px;
  }

  /* .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 13px;
    margin: 25px 7px 0px 7px;
  } */

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 13px;
    margin: 12px 11px 0px 11px;
  }

  /* .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 13px;
    margin: 25px 7px 0px 7px;
  } */

  .second-header-sec .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0%;
    text-wrap: nowrap;
  }

  .second-header-sec .login {
    font-size: 13px;
    margin-top: 0px;
  }

  /* .second-header-sec .navbar-nav .dropdown {
    width: 299px;
  } */
  .second-header-sec .dropdown-toggle::after {
    position: absolute;
    right: -8px;
    margin-top: 7px;
  }

  .second-header-sec .dropdown-menu {
    width: 299px;
  }

  /* .second-header-sec .navbar .navbar-collapse .all-icon {
    width: 35%;
  } */

  .second-header-sec .header-icon-holder {
    justify-content: right;
  }

  .second-header-sec .cart-logo {
    margin: 0px 3px;
  }

  .second-header-sec .navbar-expand-lg .navbar-collapse {
    justify-content: space-between;
  }

  /* .second-header-sec .navbar-nav .nav-link {
    font-size: 12px;
    margin: 18px 7px 0px 7px;
  }

  .second-header-sec .navbar-nav .nav-link.active {
    font-size: 12px;
    margin: 18px 7px 0px 7px;
  } */
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .second-header-sec .headlogo {
    height: 20px;
  }

  /* .second-header-sec .navbar-nav .nav-link:hover {
    color: #000;
    font-size: 13px;
    margin: 25px 7px 0px 7px;
  } */

  .second-header-sec .navbar-nav .nav-link.active {
    color: #000;
    font-size: 13px;
    margin: 25px 7px 0px 7px;
  }

  /* .second-header-sec .navbar-nav .nav-link.show {
    color: #000;
    font-size: 13px;
    margin: 25px 7px 0px 7px;
  } */

  .second-header-sec .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0px;
    padding-right: 0%;
    text-wrap: nowrap;
  }

  .second-header-sec .login {
    font-size: 13px;
  }

  /* .second-header-sec .navbar-nav .dropdown {
    width: 299px;
  } */
  .second-header-sec .dropdown-toggle::after {
    position: absolute;
    right: -8px;
    margin-top: 7px;
  }

  .second-header-sec .dropdown-menu {
    width: 299px;
  }

  /* .second-header-sec .navbar .navbar-collapse .all-icon {
    width: 35%;
  } */

  .second-header-sec .cart-logo {
    margin: 0px 4px;
  }

  .second-header-sec .navbar-expand-lg .navbar-collapse {
    justify-content: space-between;
  }

  .second-header-sec .navbar-nav .nav-link {
    font-size: 12px;
    margin: 15px 7px 5px 7px;
  }

  .second-header-sec .navbar-nav .nav-link.active {
    font-size: 12px;
    margin: 15px 7px 0px 7px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .second-header-sec .navbar-expand-lg .navbar-collapse {
    justify-content: space-between;
  }

  /* .second-header-sec .navbar .navbar-collapse .all-icon {
    width: 38%;
  } */

  .second-header-sec .navbar-nav .nav-link {
    font-size: 13px;
    margin: 10px 8px 0px 8px;
  }

  .second-header-sec .navbar-nav .nav-link.active {
    font-size: 13px;
    margin: 10px 8px 0px 8px;
  }

  .second-header-sec .headlogo {
    height: 30px;
  }

  .second-header-sec .cart-logo {
    margin: 0px 7px;
  }

  .second-header-sec .login {
    font-size: 14px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .second-header-sec .navbar-expand-lg .navbar-collapse {
    justify-content: space-between;
  }

  /* .second-header-sec .navbar .navbar-collapse .all-icon {
    width: 30%;
  } */

  .second-header-sec .navbar-nav .nav-link {
    font-size: 14px;
    margin: 15px 5px 0px 5px;
    text-wrap: nowrap;
  }

  .second-header-sec .navbar-nav .nav-link.active {
    font-size: 14px;
    margin: 15px 5px 0px 5px;
  }

  .second-header-sec .cart-logo {
    margin: 0px 9px;
  }
}

@media (min-width: 1600px) and (max-width: 1699px) {
  .second-header-sec .navbar-expand-lg .navbar-collapse {
    justify-content: space-around;
  }

  /* .second-header-sec .navbar .navbar-collapse .all-icon {
    width: 30%;
  } */

  .second-header-sec .navbar-nav .nav-link {
    font-size: 14px;
    margin: 15px 5px 0px 5px;
    text-wrap: nowrap;
  }

  .second-header-sec .navbar-nav .nav-link.active {
    font-size: 14px;
    margin: 15px 5px 0px 5px;
  }
}

@media (min-width: 1700px) and (max-width: 1770px) {
  .second-header-sec .navbar .navbar-collapse .all-icon {
    width: 42%;
  }

  .second-header-sec .navbar-expand-lg .navbar-collapse {
    justify-content: center;
  }

  .second-header-sec .cart-logo {
    margin: 10px 0px 0px 15px;
  }
}

@media (min-width: 1770px) and (max-width: 2000px) {
  .second-header-sec .navbar .navbar-collapse .all-icon {
    width: 42%;
  }

  .second-header-sec .navbar-expand-lg .navbar-collapse {
    justify-content: center;
  }
}

.second-header-sec .searchinpuuhead-mob {
  display: none;
}

.searchinpuuhead {
  display: block;
}


@media (min-width: 0px) and (max-width: 991px) {
  .second-header-sec .headlogo {
    width: auto;
    height: 30px;
  }

  .second-header-sec .searchinpuuhead-mob {
    display: block;
  }

  .second-header-sec .searchinpuuhead {
    display: none;
  }
  .desktop .list-group{
    display:none;
  }

  .mobsearchhed .searchinpuuhead-mob {
    position: relative;
    margin-top: 10px;
  }
  .mobsearchhed .searchinpuuhead-mob  .list-group{
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0px;
  }
  .mobsearchhed .searchinpuuhead-mob  .list-group .list-group-item{
    font-size: 12px;
  }

  .mobsearchhed .searchinpuuhead-mob .btnserch {
    position: absolute;
    right: 0px;
    top: 1px;
    background-color: #faa700;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
  }
}


@media (min-width: 1200px) and (max-width: 1399px) {
  .ginanimationimg {
    width: 75%;
    height: auto;
    position: absolute;
    top: 50%;
    right: 75%;
    /* -o-animation: cssAnimation 0s ease-in 10s forwards; */
    animation: cssAnimation 0s ease-in 50s forwards;
}
}

@media (min-width: 1400px) and (max-width: 1700px) {
  .ginanimationimg {
    right: 56%;
  }
}

@media (min-width: 2000px) and (max-width: 2600px) {
  .second-header-sec .navbar-nav .nav-link {
    margin: 12px 8px 0px 8px;
}
.ginanimationimg {
  width: 70%;
  right: 56%;
}
}