.Veiw_All_Card {
  background: #faa700 !important;
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.Veiw_All_Card .Veiw_All_Card_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 293px;
  width: 50%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}

.Veiw_All_Card .Veiw_All_Card_content .view-text-holder p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

@media (min-width: 0px) and (max-width: 374px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 381px;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 435px;
  }
}

@media (min-width: 425px) and (max-width: 485px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 280px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 339px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 339px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 263px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 263px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 357px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 286px;
  }
}

@media (min-width: 1300px) and (max-width: 1499px) {
  .Veiw_All_Card .Veiw_All_Card_content {
    height: 285px;
  }
}
