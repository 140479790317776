.all-product-details {
  margin-top: 2%;
  margin-bottom: 12%;
}

.all-product-details .breadcrumb-item + .breadcrumb-item::before {
  content: url("../../../../../public/assets/Image/Icon/arrow-forward.png");
}

.all-product-details .breadcrumb-item {
  font-size: 13px;
  font-weight: 500;
}

.all-product-details .breadcrumb-item.active {
  color: #000;
}

.all-product-details a {
  font-size: 13px;
  font-weight: 600;
  color: #0f3768;
}

@media (min-width: 0px) and (max-width: 321px) {
  .all-product-details {
    margin-bottom: 40%;
  }
}

@media (min-width: 321px) and (max-width: 486px) {
  .all-product-details {
    margin-bottom: 35%;
  }
}

@media (min-width: 486px) and (max-width: 567px) {
  .all-product-details {
    margin-bottom: 30%;
  }
}

@media (min-width: 568px) and (max-width: 767px) {
  .all-product-details {
    margin-bottom: 25%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .all-product-details {
    margin-bottom: 20%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .all-product-details {
    margin-bottom: 15%;
  }
}
