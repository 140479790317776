.footer {
  background: #000 0% 0% no-repeat padding-box;
  padding-top: 79px;
}

.footer .headlogo {
  width: auto;
  height: 40px;
  margin-bottom: 13px;
  background: #fff;
}

.footer .nav {
  margin-top: 23px;
}

.footer .nav-link {
  color: #fff;
  font-size: 14px;
  padding-left: 0%;
  /* margin: 0px 11px; */
}

.footer .nav-link:hover {
  color: #fff;
  font-size: 14px;
  padding-left: 0%;
  /* margin: 0px 11px; */
}

.footer .nav-link.active {
  color: #fff;
  font-size: 14px;
  padding-left: 0%;
  /* margin: 0px 11px; */
}

.footer .nav-link:focus {
  color: #fff;
  font-size: 14px;
  padding-left: 0%;
  /* margin: 0px 11px; */
}

.footer a {
  color: #fff;cursor: pointer;
}
.footer a:hover{
  color: #ffce00;
}

.footer .text-area-loaction {
  margin-top: 5px;
}

.footer .hideingp {
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  margin: 15px 0px 0px 0px;
}
.footer p:hover{
  color: #ffce00;
}


.footer .scanner-img-holder{
  height: 80px;
  margin-bottom: 15px;
}

.footer .scanner-img-holder .scanner-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  object-position: left;
}

.footer .adddppp{
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 5px;
}

.footer .email-box .form-control {
  border-radius: 0%;
  padding: 0px;
  border: none;
  font-size: 15px;
  color: #000;
  background-color: transparent;
  padding-left: 10px;
}

.footer .email-box .form-control::placeholder {
  font-size: 15px;
  color: #000;
}

.footer .email-box .form-control:focus {
  box-shadow: none;
}

.footer .email-box .input-group-text {
  border: none;
  background-color: transparent;
  padding-right: 0px;
}

/* .footer .email-box .input-group {
    padding: 0px;
    background-color: transparent;
    border-bottom: 1px solid #FFF;
} */

/* footer banner css start */

.footer-bannermain {
  position: relative;
}

.location-holder .call-holder {
  margin-bottom: 10px;
}

/* .footer .address-holder .text-holder {
    margin: 23px 0px;
} */

.footer .address-holder .text-holder p {
  font-size: 13px;
  color: #fff;
  margin-bottom: 0%;
}

.footer .address-holder .social-icon {
  margin-left: auto;
  margin-top: 34px;
}

.footer .address-holder .social-icon .sociallogo {
  width: 30px;
  height: 30px;
  margin: 0px 5px;
}

.footer .copyright-holder {
  border-top: 1px solid #fff;
  margin-bottom: 2%;
}

.footer .copyright-holder p {
  color: #fff;
  font-size: 12px;
  margin: 14px 0px;
}

.footer .copyright-holder p:hover {
  color: #ffce00;
}

.footer .location-icon {
  font-size: 20px;
  margin-left: 2px;
}

.footer .footerbottom {
  display: flex;
  justify-content: flex-end;
}
.footer .boomcor {
  color: #ffce00;
  font-size: 19px;
  font-weight: 700;
  text-transform: uppercase;
}

/* @media (min-width: 0px) and (max-width: 991px) {
  .footer .email-box {
    margin-top: 40px;
  }

  .footer .address-holder .text-holder {
    margin-bottom: 50px;
  }

  .footer .headlogo {
    width: 106px;
    height: 68px;
  }

  .footer .nav {
    margin-top: 32px;
  }
}

@media (min-width: 0px) and (max-width: 486px) {
  .footer .address-holder .text-holder p {
    font-size: 12px;
  }

  .footer .address-holder .social-icon .sociallogo {
    width: 25px;
    height: 25px;
    margin: 0px 2px;
  }

  .footer .email-box .form-control {
    font-size: 12px;
  }

  .footer .email-box .form-control::placeholder {
    font-size: 12px;
  }

  .footer .nav-link {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
  }

  .footer .nav-link:hover {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
  }

  .footer .nav-link.active {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
  }

  .footer .nav-link:focus {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 13px;
  }

  .footer .email-box {
    margin-top: 23px !important;
  }

  .footer {
    padding-top: 43px;
  }
} */

.footer .location-holder {
  display: flex;
  justify-content: center;
  margin-top: 4px;
  padding-bottom: 14px;
}

.footer .location-holder .call-holder span {
  color: #fff;
  font-size: 13px;
  cursor: pointer;
}
.footer .location-holder .call-holder span:hover{
  color: #ffce00;
}

.footer .location-holder .footerhead {
  color: #ffce00;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
}

.footer .location-holder .footerhead.link_text:hover{
  color: #fff;
  text-decoration: underline;
}

.footer .location-holder .call-holder .call-icon {
  color: #fff;
}

.footer .address-holder {
  color: #fff;
  font-size: 13px;
}

.soccicooo-flex {
  display: flex;
  margin-top: 10px;
}

.soccicooo-flex .ico-div {
  display: flex;
}

.soccicooo-flex .footerhead {
  color: #fff;
  font-size: 16px;
}

.helpdeskposs {
  position: relative;
  width: 100%;
  height: 100%;
}

.helpdeskposs .jfd-bot {
  /* position: absolute;
  bottom: -135px; */
}

.footer .whatssiccc {
  width: 20px;
  margin-right: 10px;
}



/* subscribe-banner-css start*/
.footer-banner {
  margin-top: -170px;
}

.footer-bannermain {
  position: relative;
  margin-bottom: 38px;
}

.footer-bannermain .ban-contentmain {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.footer-bannermain .banconts {
  padding-top: 20px;
  padding-left: 70px;
  padding-right: 10px;
}

.footer-bannermain .banconts .newssetl {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.footer-bannermain .banconts .getss {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.footer .email-box .input-group {
  padding: 0px 5px;
  background-color: #fff;
  border-radius: 25px;
}

.footer-bannermain .subscribe-btn {
  border-radius: 21px;
  /* height: 38px; */
}

.footer-bannermain .footerimg {
  width: 100%;
  height: 202px;
  border-radius: 10px;
}

.socialIcon {
  width: 26px;
  /* margin-top: 10px; */
  margin-left: 11px;
}

.socialicccc .icccc {
  height: 30px;
  width: auto;
  margin-right: 15px;
  background-color: #fff;
  /* padding: 5px; */
  border-radius: 3px;
}

.socialicccc {
  margin-bottom: 20px;
}

.mobwhats {
  display: none !important;
}

.webwhats {
  display: block !important;
}

@media (min-width: 0px) and (max-width: 991px) {
  .mobwhats {
    display: block !important;
  }
  .footer .boomcor {
    font-size: 13px;
}
  .webwhats {
    display: none !important;
  }

  .footer-bannermain .banconts {
    padding-top: 15px;
    padding-left: 10px;
  }

  .footer-bannermain .banconts .newssetl {
    font-size: 16px;
  }

  .footer .footerbottom {
    justify-content: start;
  }

  .socialIcon {
    width: 22px;
    margin-top: 0px;
  }

  .socialIcon {
    margin-left: 5px;
  }

  .soccicooo-flex {
    margin-top: 10px;
  }

  .footer .copyright-holder {
    padding-top: 10px;
  }

  .footer .copyright-holder p {
    margin: 7px 5px;
    font-size: 12px;
  }

  .soccicooo-flex .footerhead {
    font-size: 14px;
  }

  .helpdeskposs .jfd-bot {
    position: unset;
  }
}

@media (min-width: 0px) and (max-width: 575px) {




  .footer .footerbottom {
    display: flex;
    justify-content: start;
  }
}

/* subscribe-banner-css end*/

/* @media(min-width:0px) and (max-width:991px) {
    .footer .email-box {
        margin-top: 40px;
    }

    .footer .address-holder .text-holder {
        margin-bottom: 50px;
    }

    .footer .headlogo {
        width: 106px;
        height: 68px;
    }

    .footer .nav {
        margin-top: 32px;
    }
}

@media(min-width:0px) and (max-width:486px) {
    .footer .address-holder .text-holder p {
        font-size: 12px;
    }

    .footer .address-holder .social-icon .sociallogo {
        width: 25px;
        height: 25px;
        margin: 0px 2px;
    }

    .footer .email-box .form-control {
        font-size: 12px;

    }

    .footer .email-box .form-control::placeholder {
        font-size: 12px;

    }

    .footer .nav-link {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 13px;
    }

    .footer .nav-link:hover {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 13px;
    }

    .footer .nav-link.active {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 13px;
    }

    .footer .nav-link:focus {
        padding-right: 5px;
        padding-left: 5px;
        font-size: 13px;
    }

    .footer .email-box {
        margin-top: 23px !important;
    }

    .footer {
        padding-top: 43px;
    }
} */

@media (min-width: 992px) and (max-width: 1200px) {
  .footer .nav-link:hover {
    margin: 0px 4px;
  }
  .footer .boomcor {
    font-size: 13px;
}


  .footer .nav-link.active {
    margin: 0px 4px;
  }

  .footer .nav-link {
    margin: 0px 4px;
  }

  .footer .nav-link:focus {
    margin: 0px 4px;
  }

  .soccicooo-flex .footerhead {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer .nav-link:hover {
    margin: 0px 4px;
  }

  .footer .nav-link.active {
    margin: 0px 4px;
  }

  .footer .nav-link {
    margin: 0px 4px;
  }

  .footer .nav-link:focus {
    margin: 0px 4px;
  }

  .footer .nav {
    margin-top: 46px;
  }

  .footer .address-holder .social-icon {
    margin-top: 19px;
    margin-bottom: 16px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .footer .nav-link:hover {
    margin: 0px 0px;
    padding: 0px 6px;
    font-size: 13px;
  }

  .footer-banner {
    margin-top: 0px;
}

  .footer .nav-link.active {
    margin: 0px 0px;
    padding: 0px 6px;
    font-size: 13px;
  }

  .footer .nav-link {
    margin: 0px 0px;
    padding: 0px 6px;
    font-size: 13px;
  }

  .footer .nav-link:focus {
    margin: 0px 0px;
    padding: 0px 6px;
    font-size: 13px;
  }

  .footer .nav {
    margin-top: 9px;
    margin-bottom: 19px;
  }

  .footer .address-holder .social-icon {
    margin-top: 19px;
    margin-bottom: 16px;
  }

  .footer .location-holder {
    display: unset;
  }

}

@media (min-width: 1199px) and (max-width: 1299px) {
.footer .boomcor {
  font-size: 16px;
}
}

@media (min-width: 1299px) and (max-width: 1399px) {
  .footer .boomcor {
    font-size: 16px;
  }
  }