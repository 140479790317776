.first-vendor-details {
  cursor: pointer !important;
}
.first-vendor-details .description-class .nav-pills .nav-link {
  color: #333333;
  font-size: 15px;
  font-weight: 500;
}

.first-vendor-details .description-class .nav-pills .nav-link.active {
  color: #000;
  font-size: 15px;
  border-bottom: 2px solid #000;
  border-radius: 0px;
  background: none;
  font-weight: 600;
}
.first-vendor-details .description-class .nav.nav-pills {
  border-bottom: 2px solid #a2a2a2;
}

.first-vendor-details .button-effect .heart-icon.active {
  color: #df4223;
}

.first-vendor-details .description-class .description-class-title {
  font-size: 20px;
  color: #333333;
  font-weight: 600;
}

.first-vendor-details .add-to-cart-btn {
  border: 1px solid #0f3768;
  background: transparent;
  border-radius: 25px;
  /* padding: 5px 20px; */
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  width: 168px;
  height: 38px;
  margin-bottom: 10px;
}

.first-vendor-details .add-to-cart-btn:hover {
  background: #faa700;
  border-radius: 25px;
  border: 1px solid #faa700;
  /* padding: 5px 20px; */
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  width: 168px;
  height: 38px;
}

.first-vendor-details .button-effect {
  position: relative;
  display: flex;
  align-items: center;
}

.first-vendor-details .blue-bg {
  bottom: 0px;
  background: #fff;
  border: 1px solid #0f3768;
  border-radius: 50px;
  width: 168px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.first-vendor-details .circle-holder1 {
  border-radius: 100%;
  background: #fff;
  width: 32px;
  height: 32px;
  margin: 3px;
}

.first-vendor-details .blue-bg .minus-btn {
  border-radius: 100%;
  color: #212529;
  background: #c9c6c6;
  border: 1px solid #ccc;
  /* padding: 0px 11px; */
  width: 28px;
  height: 28px;
  margin: 2px;
}

.first-vendor-details .blue-bg .minus-btn .minus-icon {
  color: #fff;
}

.first-vendor-details .number-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

.first-vendor-details .title-details .in-stock {
  font-size: 12px;
  color: #008f18;
  font-weight: 500;
}

.first-vendor-details .title-details .min-order-text{
font-size: 15px;
color: #000;
font-weight: 500;
}

.first-vendor-details .title-details .min-order-text .qty-text{
  font-size: 15px;
  color: #008f18;
  font-weight: 600;
  }

.first-vendor-details .title-details .mrp-class {
  font-size: 15px;
  color: #df4223;
  font-weight: 500;
  margin-bottom: 0px;
}

.first-vendor-details .title-details .mrp-class span {
  font-size: 12px;
  color: #333333;
}

.first-vendor-details .title-details .return-mrp {
  font-size: 12px;
  color: #333333;
  text-decoration: line-through;
  margin-bottom: 2px;
}

.first-vendor-details .title-details h2 {
  font-size: 20px;
  font-weight: 600;
}

.first-vendor-details .text-holder-vendor-page {
  font-size: 15px;
  color: #333333;
  font-weight: 500;
  text-align: justify;
}

.first-vendor-details .text-holder-vendor-page span {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: #707070;
} 

.first-vendor-details .text-holder-vendor-page p {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #333333;
} 

.first-vendor-details .brod-title {
  font-size: 15px;
  color: #333333;
  font-weight: 600;
}

.first-vendor-details .brod-subtitle {
  font-size: 15px;
  color: #333333;
}

.first-vendor-details .brod-title-2 {
  font-size: 12px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 3px;
}

.first-vendor-details .brod-subtitle-2 {
  font-size: 12px;
  color: #333333;
  margin-bottom: 3px;
}

.first-vendor-details .orange-bg-details {
  background: #faa70069;
  border-radius: 5px;
  padding: 10px 15px;
  margin-top: 3%;
}

.first-vendor .bred-cumb-vendor a {
  font-size: 13px;
  color: #0f3768;
  font-weight: 700;
}

.first-vendor .bred-cumb-vendor .breadcrumb-item.active {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
  padding-top: 3px;
}

.first-vendor .breadcrumb-item + .breadcrumb-item::before {
  content: url("../../../../../public/assets/Image/Icon/arrow-forward.png");
}
.button-effect .qntybtns {
  margin-left: 50px;
  align-items: center;
}

.button-effect .qntybtns .pluss-btn {
  width: 30px;
  height: 30px;
  background: #c9c6c6 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  border: none;
}

.button-effect .qntybtns .min-btns {
  background: #d1d1d1 0% 0% no-repeat padding-box;
  color: #fff;
}
.button-effect .cardcounts {
  margin: 0px 10px;
}

/* variant-holder style start */

.first-vendor-details .variant-color-div {
  margin-top: 20px;
}

.first-vendor-details .variant-holder {
  margin-top: 30px;
}

.first-vendor-details .variant-holder .heading-holder h3 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.first-vendor-details .variant-holder .variant-heading-holder h2 {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.first-vendor-details .variant-holder .color-variant-holder .variants {
  display: flex;
  flex-flow: wrap;
}

.first-vendor-details
  .variant-color-div
  .Size-variant-holder
  .Size
  .nav-pills
  .nav-link {
  background: #efefef;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin: 5px 5px;
  padding: 4px 15px;
}

.first-vendor-details
  .variant-color-div
  .Size-variant-holder
  .Size
  .nav-pills
  .nav-link.active {
  background: #efefef;
  border: 0.800000011920929px solid #afafaf;
}
.multivar-divv .nav-link {
  color: #000;
  font-size: 14px;
  border: 1px solid #ccc;
  margin-right: 9px;
  padding: 3px 12px;
  font-weight: 500;
}

.multivar-divv .nav-link.active{
  background-color: #2fbf95 !important;
}
.tabcontttttt .coltest {
  font-size: 14px !important;
  margin: 0px 5px 0px 0px !important;
  cursor: pointer;
}

.horizontal-nav .sub-variant-container{
  display: flex;
}
.horizontal-nav .sub-variant-container .sub-variant-item{
  margin: 0px 5px;
  font-size: 14px;
}

.tabcontttttt .sub-variant-container{
  display: flex;
}

.tabcontttttt .sub-variant-container .sub-variant-item{
  margin: 0px 5px;
  font-size: 14px;
  cursor: pointer;
}

.tabcontttttt .sub-variant-container .sub-variant-item.active{
 font-weight: 600;
 border-bottom: 2px solid #2fbf95;
}


.horizontal-nav .nav-link{
  color: #000;
  font-size: 14px;
}
.horizontal-nav .nav-link.active{
  background-color: #2fbf95;
  color: #fff;
  border-radius: 5px;
}


.horizontal-nav .nav-link:hover {
  background-color: #198754;
}

/* variant-holder style end */

/* Media */
@media (min-width: 0px) and (max-width: 320px) {
  .first-vendor-details .title-details h2 {
    font-size: 15px;
  }

  .first-vendor-details .title-details .mrp-class {
    font-size: 12px;
  }

  .first-vendor-details .title-details .mrp-class span {
    font-size: 10px;
  }

  .first-vendor-details .add-to-cart-btn {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .add-to-cart-btn:hover {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .description-class .description-class-title {
    font-size: 16px;
  }

  .first-vendor-details .nav-pills .nav-link {
    font-size: 12px;
  }

  .first-vendor-details .nav-pills .nav-link.active {
    font-size: 12px;
  }

  .first-vendor-details .text-holder-vendor-page {
    font-size: 12px;
  }

  .first-vendor-details .blue-bg {
    width: 133px;
  }

  /* .first-vendor-details .button-effect {
    display: unset;
  } */

  .button-effect .qntybtns {
    margin-left: 0px;
    margin-top: 20px;
    align-items: center;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .first-vendor-details .title-details h2 {
    font-size: 15px;
  }

  .first-vendor-details .title-details .mrp-class {
    font-size: 12px;
  }

  .first-vendor-details .title-details .mrp-class span {
    font-size: 10px;
  }

  .first-vendor-details .add-to-cart-btn {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .add-to-cart-btn:hover {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .blue-bg {
    width: 127px;
    top: -8px;
  }

  .first-vendor-details .description-class .description-class-title {
    font-size: 16px;
  }

  .first-vendor-details .nav-pills .nav-link {
    font-size: 13px;
  }

  .first-vendor-details .nav-pills .nav-link.active {
    font-size: 13px;
  }

  .first-vendor-details .text-holder-vendor-page {
    font-size: 13px;
  }
  .first-vendor-details .button-effect {
    display: unset;
  }

  .button-effect .qntybtns {
    margin-left: 0px;
    margin-top: 20px;
    align-items: center;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .first-vendor-details .title-details h2 {
    font-size: 17px;
  }

  .first-vendor-details .title-details .mrp-class {
    font-size: 13px;
  }

  .first-vendor-details .title-details .mrp-class span {
    font-size: 11px;
  }

  .first-vendor-details .add-to-cart-btn {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .add-to-cart-btn:hover {
    font-size: 12px;
    font-weight: 500;
    width: 127px;
  }

  .first-vendor-details .blue-bg {
    width: 133px;
  }

  .first-vendor-details .description-class .description-class-title {
    font-size: 17px;
  }

  .first-vendor-details .nav-pills .nav-link {
    font-size: 13px;
  }

  .first-vendor-details .nav-pills .nav-link.active {
    font-size: 13px;
  }

  .first-vendor-details .text-holder-vendor-page {
    font-size: 13px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .first-vendor-details .title-details h2 {
    font-size: 17px;
  }

  .first-vendor-details .title-details .mrp-class {
    font-size: 13px;
  }

  .first-vendor-details .title-details .mrp-class span {
    font-size: 11px;
  }

  .first-vendor-details .description-class .description-class-title {
    font-size: 17px;
  }

  .first-vendor-details .nav-pills .nav-link {
    font-size: 13px;
  }

  .first-vendor-details .nav-pills .nav-link.active {
    font-size: 13px;
  }

  .first-vendor-details .text-holder-vendor-page {
    font-size: 13px;
  }
}
