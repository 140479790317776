.my-account{

}

.my-account .product-container {
    margin-bottom: 20px;
}

.my-account .product-container .return-button{

}


.my-account .product-container .return-button .return-btn {
    color: #fff;
    background-color: #faa700;
    font-size: 14px;
    font-weight: 400;
    padding: 7px 50px;
    /* margin-left: 10px; */
    border: none;
    border-radius: 50px;
    margin-top: 2%;
}


.my-account .product-container .black_btn_holder .SubmitBtn {
    margin-right: 10px;
}

.my-account .product-container .divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 14px;
}

.my-account .product-container .divider hr{
  width: 80%;
}




