

.Category_Card .image-holder .banlogo {
  width: 100%;
  height: 100%;
}

.Category_Card .image-holder .text-overlay {
  position: absolute;
  top: 28px;
  left: 0;
  z-index: 1;
  right: 0;
}


.Category_Card .image-holder {
  height: 269px;
  position: relative;
  text-align: center;
  aspect-ratio: 3/3.16;
}


@media (min-width: 992px) and (max-width: 1023px) {
.Category_Card .image-holder {
  height: 269px;
  position: relative;
  text-align: center;
  aspect-ratio: 3/4.31;
}
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Category_Card .image-holder {
    height: 269px;
    position: relative;
    text-align: center;
    aspect-ratio: 3/4.31;
  }
  }