.select-categories {
  margin-top: 2%;
  margin-bottom: 10%;
}

.select-categories .nav-pills .nav-link.active {
  background: transparent;
}

.select-categories .view-all {
  margin-top: 2%;
}

.select-categories .product-image {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.select-categories .product-image .category-image {
  width: 200px;
  height: 128px;
  border-radius: 5px;
  transition: 0.3s;
  object-fit: cover;
  object-position: top;
  aspect-ratio: 1/1;
}

.select-categories .product-image:hover .category-image {
  transform: scale(1.1);
  transition: 0.3s;
}

.select-categories .product-image .text-holder {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  width: 100%;
  /* Ensure the entire width is centered */
}

.select-categories .product-image .text-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  margin-bottom: 4%;
}

.select-categories .product-image .bg-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent linear-gradient(180deg,
      #0f376800 0%,
      #0d336020 29%,
      #031a36d2 84%,
      #00142b 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.select-categories .product-slide {
  width: auto !important;
}

.select-categories .mySwiper1 {
  margin-left: 0%;
}

.select-categories .nav-pills .nav-link.active {
  background: transparent;
  border-radius: 0%;
  position: relative;
}

.select-categories .nav-pills .nav-link {
  padding-left: 10px;
  padding-right: 0px;
  margin-bottom: 20px;
}

.select-categories .nav-pills .nav-link.active .triangle-right {
  display: block !important;
  /* border-top: 7px solid transparent;
  border-left: 13px solid #DF4223;
  border-bottom: 7px solid transparent; */
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #df4223;
  position: absolute;
  left: 47%;
  bottom: -8%;
  z-index: 99999;
  /* transform: rotate(91deg); */
}

.select-categories .nav-pills .nav-link .triangle-right {
  display: none;
  z-index: 99999;
}

.select-categories .select-categories-slider {
  padding: 10px 0px;
}

.select-categories .desktop_view_product_category_slider {
  display: block;
}

.select-categories .mobile_view_product_category_slider {
  display: none;
}

.select-categories .nav-pills .nav-link:focus-within {
  border: 1px solid #000;
}

.select-categories .slider-holder .empty-heading-holder h3 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin-top: 50px;
}

.rangbar_holder .range-slider .range-slider__thumb {
  width: 15px;
  height: 15px;
}

@media (min-width: 0px) and (max-width: 991px) {
  .select-categories .product-image .text-holder p {
    font-size: 14px;
  }

  .select-categories .desktop_view_product_category_slider {
    display: none;
  }

  .select-categories .mobile_view_product_category_slider {
    display: block;
  }

  .select-categories .slider-holder .empty-heading-holder h3 {
    margin-top: -22px;
  }

  .select-categories .select-categories-slider {
    padding: 30px 0px;
  }

  .select-categories {
    margin-bottom: 10%;
  }

  .select-categories .mySwiper1 {
    overflow-x: scroll;
  }
}