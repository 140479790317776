.Successfull_Modal .modal-header {
  border: none;
}

.Successfull_Modal .modal-body {
  border: none;
}

.Successfull_Modal .modal-content {
  border: none;
  box-shadow: 0px 3px 17px #00000012;
}

.modal-backdrop.show {
  opacity: 0.8 !important;
  background: #000000 !important;
}

.Successfull_Modal .modal-body p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.Successfull_Modal .modal-body h4 {
  font-size: 14px;
  font-weight: bold;
  color: #f05340;
  text-decoration: underline;
}

.Successfull_Modal .Add_Address_icon_holder{
margin: 10px auto;
text-align: center;
}

.Successfull_Modal .Add_Address_home_icon{
font-size: 50px;
color: #faa700;
}
