.checkout-inner-steps .radio_input {
  padding: 0px 0px 0px 25px;
}

.checkout-inner-steps .address-holders .right_border {
  border-right: 2px solid #707070;
  padding: 0px 31px 0px 0px !important;
}

.checkout-inner-steps .address-holders .left_border {
  padding: 0px 0px 0px 31px !important;
}

.checkout-inner-steps .radio_input .radio_input_field {
  margin-bottom: 5%;
}

.checkout-inner-steps .Account_Address .checkout-inner-steps .radio_input .radio_input_field {
  width: 100%;
  margin-left: 11px;
}

.checkout-inner-steps .Account_Address .checkout-inner-steps {
  margin-top: 30px;
  height: 350px;
  overflow: hidden;
  overflow-y: scroll;
}

.checkout-inner-steps .main-heading-text .form-check-label {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #323232;
}

.checkout-inner-steps .radio_input .flex-radio-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.checkout-inner-steps .radio_input .form-label {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
}

.checkout-inner-steps .radio_input .form-control {
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  color: #323232;
  margin-left: 10px;
  padding: 5px 5px;
}

.checkout-inner-steps .radio_input .form-control:focus {
  box-shadow: none;
}

.checkout-inner-steps .address_input .flex-radio-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.checkout-inner-steps .address_input .flex-radio-input .form-label {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  width: 25%;
}

.checkout-inner-steps .address_input .address-name-select {
  width: 100%;
}

.checkout-inner-steps
  .address_input
  .address-name-select
  .selectOptions__control {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: #a2a2a2;
  border: none;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.checkout-inner-steps .address_input {
  margin-bottom: 2%;
}

.checkout-inner-steps .address_input .form-control {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #a2a2a2;
  border: none;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 40px;
}

.checkout-inner-steps .address_input .form-control:focus {
  box-shadow: none;
}

@media (min-width: 0px) and (max-width: 320px) {
  .checkout-inner-steps .address-holders .right_border {
    border-right: none;
    padding: 0px 0px 0px 0px !important;
    margin-bottom: 5%;
  }

  .checkout-process-step-form .progress-bar-container .step-text {
    font-size: 9px;
    text-wrap: nowrap;
}

  .checkout-process-step-form .progress-bar-container .step-container {
    margin-right: 5px;
  }

  .checkout-inner-steps .address-holders .left_border {
    padding: 0px 0px 0px 0px !important;
  }

  .checkout-inner-steps .address_input .flex-radio-input {
    display: block;
    margin-bottom: 5%;
  }

  .checkout-inner-steps .address_input .flex-radio-input .form-label {
    width: 100%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .checkout-inner-steps .address-holders .right_border {
    border-right: none;
    padding: 0px 0px 0px 0px !important;
    margin-bottom: 5%;
  }

  .checkout-process-step-form .progress-bar-container .step-text {
    font-size: 10px;
  }

  .checkout-process-step-form .progress-bar-container .step-container {
    margin-right: 5px;
  }

  .checkout-inner-steps .address-holders .left_border {
    padding: 0px 0px 0px 0px !important;
  }

  .checkout-inner-steps .address_input .flex-radio-input {
    display: block;
    margin-bottom: 5%;
  }

  .checkout-inner-steps .address_input .flex-radio-input .form-label {
    width: 100%;
  }
}

@media (min-width: 486px) and (max-width: 576px) {
  .checkout-inner-steps .address-holders .right_border {
    border-right: none;
    padding: 0px 0px 0px 0px !important;
    margin-bottom: 5%;
  }

  .checkout-inner-steps .address-holders .left_border {
    padding: 0px 0px 0px 0px !important;
  }

  .checkout-inner-steps .address_input .flex-radio-input {
    display: block;
    margin-bottom: 5%;
  }

  .checkout-inner-steps .address_input .flex-radio-input .form-label {
    width: 100%;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .checkout-inner-steps .address-holders .right_border {
    border-right: none;
    padding: 0px 0px 0px 0px !important;
    margin-bottom: 5%;
  }

  .checkout-inner-steps .address-holders .left_border {
    padding: 0px 0px 0px 0px !important;
  }

  .checkout-inner-steps .address_input .flex-radio-input {
    display: block;
    margin-bottom: 5%;
  }

  .checkout-inner-steps .address_input .flex-radio-input .form-label {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .checkout-inner-steps .address-holders .right_border {
    border-right: none;
    padding: 0px 0px 0px 0px !important;
    margin-bottom: 5%;
  }

  .checkout-inner-steps .address-holders .left_border {
    padding: 0px 0px 0px 0px !important;
  }

  .checkout-inner-steps .address_input .flex-radio-input .form-label {
    width: 40%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .checkout-inner-steps .address_input .flex-radio-input .form-label {
    width: 40%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .checkout-inner-steps .address_input .flex-radio-input .form-label {
    width: 40%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .checkout-inner-steps .address_input .flex-radio-input .form-label {
    width: 40%;
  }
}
