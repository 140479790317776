
/* notification offcanvas style start */
.notification-offcanvas.offcanvas.offcanvas-end {
    top: 69px;
    background: #f9f9f9 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 17px #00000026;
    border-radius: 10px 0px 0px 10px;
    border: none;
  }
  
  .notification-offcanvas .offcanvas-header {
    border-bottom: 1px solid #ccc;
  }
  
  .offcanvas-backdrop {
    top: 0px !important;
    opacity: 0 !important;
  }

  .Notification.offcanvas-backdrop {
    top: 0px !important;
    opacity: 0 !important;
  }
  
  .notification-offcanvas .notifications-banner-img-holder {
    aspect-ratio: 1/0.3;
  }
  
  .notification-offcanvas
    .notifications-banner-img-holder
    .notifications-banner-img {
    width: 100%;
    height: 100%;
  }
  
  .notification-offcanvas .offcanvas-title.h5 {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  
  .notification-offcanvas .offcanvas-header .btn-close {
    font-size: 12px;
    opacity: 1;
  }
  .notification-offcanvas  .nav-pills .nav-link:hover{
    background: #faa700;
  }
  
  .notification-offcanvas  .nav-pills .nav-link.active,
  .notification-offcanvas  .nav-pills .show > .nav-link {
    background: #ff830e;
    border-radius: 50px;
    color: #ffffff;
  }
  
  
  .notification-offcanvas .notification-tab-holder {
    margin-top: 20px;
  }
  
  .notification-offcanvas .nav-pills .nav-link {
    background: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #000000;
  }
  
  /* notification offcanvas style end */