@font-face {
  font-family: Oleo Script;
  src: url("../../../../public/assets/Image/Coupon/Oleo_Script/OleoScript-Regular.ttf");
}

.Birthday_Modal .content-holder .Birthday-video-holder .videosec {
  width: 100%;
  height: 100%;
}

.Birthday_Modal .modal-content {
  background: #000 0% 0% no-repeat padding-box;
  border: 1px solid #000;
}

.Birthday_Modal .modal-content .logo-holder {
  text-align: center;
  height: 50px;
  margin-bottom: 13px;
}

.Birthday_Modal .modal-content .logo-holder .img-logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}



.Birthday_Modal .content-holder .Birthday-video-holder .video-holder {
  width: 100%;
  height: 270px;
  aspect-ratio: 1/0.8;
}

.Birthday_Modal .content-holder .Heading-text-holder {
  background: #ffc107;
  padding: 2px 0px;
  border-radius: 5px;
}

.Birthday_Modal .content-holder .Heading-text-holder h3 {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
  color: #000;
  font-family: Oleo Script;
}

.Birthday_Modal .content-holder .Wish-text-holder {
    background: #fff;
    height: 210px;
    border-radius: 10px;
    margin-top: 15px;
}


.Birthday_Modal .content-holder .Wish-text-holder p {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
  font-family: Oleo Script;
}
