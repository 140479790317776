.TermsCondition .heading-holder h1 {
  background: transparent
    linear-gradient(90deg, #ffffff 0%, #faa700 51%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
section.TermsCondition {
  margin-top: 2%;
  margin-bottom: 15%;
}
.TermsCondition li.product-text {
  text-align: left;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.TermsCondition  .list-holder {
  margin-bottom: 30px;
  font-size: 15px;
  font-weight: 500;
}


.TermsCondition p.product-text {
  text-align: left;
  margin-bottom: 7px;
}


