.near-mart-store {
  margin-top: 2%;
  margin-bottom: 12%;
}

.near-mart-store .heading-holder h1 {
  background: transparent linear-gradient(90deg, #ffffff 0%, #faa700 51%, #ffffff 100%) 0% 0% no-repeat padding-box;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.near-mart-store .slider-sec {
  margin-bottom: 3%;
}

/* .near-mart-store .veggies-section:hover .veggies.card {
  width: 100%;
  height: 298px;
  background: #f6faff 0% 0% no-repeat padding-box;
} */

.near-mart-store .silder-btn {
  position: relative;
}

.near-mart-store .mySwiper {
  padding: 0px 0px 50px 0px;
}

/* .near-mart-store .silder-btn .back-btn {
  content: url("../../../../public/assets/Image/Homepage/Popular-Categories/left.png");
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 155px;
  z-index: 999;
  left: -23px;
} */

/* .near-mart-store .silder-btn .next-btn {
  content: url("../../../../public/assets/Image/Homepage/Popular-Categories/next.png");
  width: 20px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  bottom: 155px;
  z-index: 999;
  right: -22px;
} */

.near-mart-store .swiper-button-prev.swiper-button-disabled,
.near-mart-store .swiper-button-next.swiper-button-disabled {
  display: none;
}

.near-mart-store .swiper-button-prev:after,
.near-mart-store .swiper-button-next:after {
  display: none;
}

/* .near-mart-store .swiper-pagination-bullet {
  background: #df4223;
  opacity: 1;
} */

/* .near-mart-store .veggies.card:hover .card-body .cart .background button.btn.btn-like {
    transform: translateY(0);
    animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
} */

/* .near-mart-store .veggies.card .card-body .cart {
  display: none;
}

.near-mart-store .veggies.card:hover .card-body .cart {
  display: block;
} */

/* @-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
} */

/* .slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
} */


.near-mart-store .fliter-product-slide .swiper {
  width: 100% !important;
}

.near-mart-store .fliter-product-slide .swiper .nav-link {
  text-align: center !important;
  color: #000 !important;
  text-wrap: auto;
  font-size: 14px;
}
.near-mart-store .fliter-product-slide .swiper .nav-link:hover {
  background-color: #ffe0a1;
}

.near-mart-store .fliter-product-slide .swiper .nav-link.active {
  background-color: #faa700;
}


@media (min-width: 0px) and (max-width: 420px) {
  .near-mart-store {
    margin-bottom: 35%;
  }
}

@media (min-width: 421px) and (max-width: 577px) {
  .near-mart-store {
    margin-bottom: 25%;
  }
}

@media (min-width: 578px) and (max-width: 767px) {
  .near-mart-store {
    margin-bottom: 20%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .near-mart-store .online-store p {
    margin: 23px 21px 0px 21px;
  }

  .near-mart-store .online-store h2 {
    font-size: 18px;
  }

  .near-mart-store {
    margin-bottom: 18%;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .near-mart-store .online-store h2 {
    font-size: 18px;
  }

  .near-mart-store .silder-btn .next-btn {
    right: -18px;
  }

  .near-mart-store .silder-btn .back-btn {
    left: -18px;
  }
}