.my-account .back-text-holder p {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.my-account .product_table .span-holder {
  text-align: end;
}

.my-account .product_table .span-holder span {
  font-size: 12px;
  font-weight: 500;
  color: #323232;
}

.my-account .product_table tbody tr td {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  padding: 30px 19px;
  border: 0.5px solid #7070703d;
  text-wrap: nowrap;
}

.my-account .product_table thead tr th {
  border: 0.5px solid #7070703d;
}

.product_table .viewbtttt{
  background-color: #ffce00;
    color: #000;
    font-weight: 500;
    border-radius: 5px;
    padding: 5px 10px;
    border: 0px;
    font-size: 14px;
}
.product_table .viewbtttt:hover{
  background-color: #d7b72f;
}
