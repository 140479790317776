.Notification_Card .Notification_Card_holder .text-holder  h3 {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
} 

.Notification_Card .Notification_Card_holder .text-holder  p {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    color: #757575;
    margin-bottom: 0px;
}

.Notification_Card .Notification_Card_holder  .img-holder {
    border-radius: 50px;
    aspect-ratio: 1/1;
    padding: 10px;
}

.Notification_Card .Notification_Card_holder  .img-holder .Notification_Card-img {
    width: 100%;
    height: 100%;
}

.Notification_Card .Notification_Card_holder {
    border-bottom: 1px solid #ccc;
    margin-top: 13px;
    padding-bottom: 10px;
}

.Notification_Card .Notification_Card_holder .card-content-holder  {
    display: flex;
    justify-content: center;
    align-items: center;
}