.blog-details {
  margin-top: 3%;
  margin-bottom: 12%;
}

.blog-details .breadcrumb-item + .breadcrumb-item::before {
  content: url("../../../../public/assets/Image/Icon/arrow-forward.png");
}

.blog-details .breadcrumb-item {
  font-size: 13px;
  font-weight: 400;
}

.blog-details .breadcrumb-item.active {
  color: #000;
}

.blog-details a {
  font-size: 13px;
  font-weight: 600;
  color: #0f3768;
}

.blog-details .image-holder .img-details {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  object-position: top;
}
.blog-details .image-holder {
  aspect-ratio: 1/1;
}
.blog-details .text-holder p {
  font: normal normal normal 14px/24px Roboto;
}

.blog-details .text-holder h2 {
  font-size: 16px;
  font-weight: 600;
}

.blog-details .text-holder h3 {
  font-size: 14px;
  font-weight: 600;
}

.blog-details .conclusion h4 {
  font: 14px/24px Roboto;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0px;
  color: #232323;
}

@media (min-width: 1200px) and (max-width: 1400px) {

}

@media (min-width: 992px) and (max-width: 1200px) {


  .blog-details .text-holder p {
    font: normal normal normal 13px / 20px Roboto;
  }

  .blog-details {
    margin-bottom: 15%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {


  .blog-details {
    margin-bottom: 20%;
  }
}

@media (min-width: 578px) and (max-width: 767px) {
  .blog-details {
    margin-bottom: 25%;
  }
}

@media (min-width: 486px) and (max-width: 577px) {
  .blog-details {
    margin-bottom: 30%;
  }
}

@media (min-width: 321px) and (max-width: 486px) {


  .blog-details {
    margin-bottom: 30%;
  }
}

@media (min-width: 0px) and (max-width: 321px) {


  .blog-details {
    margin-bottom: 35%;
  }
}

@media (min-width: 0px) and (max-width: 376px) {
  .blog-details .breadcrumb-item {
    font-size: 10px;
    font-weight: 500;
  }

  .blog-details .breadcrumb-item.active {
    font-size: 10px;
  }

  .blog-details {
    margin-top: 4%;
  }
}
