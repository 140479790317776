.blog {
  margin-top: 3%;
  margin-bottom: 12%;
}

.blog .card {
  border: none;
  background: #fff;
  box-shadow: 0px 0px 17px #0000001c;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 24px 10px 16px 11px;
  position: relative;
}

.blog .card .read-more-align .readmore-p .readmorefafa {
  font-size: 9px;
  margin-left: 5px;
}

.blog .recent-post-div .blog-card-holder {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.blog .recent-post-div .blog-card-holder::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.blog .recent-post-div .blog-card-holder::-webkit-scrollbar-track {
  background: #e8e8e8;
}

/* Handle */
.blog .recent-post-div .blog-card-holder::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 5px;
}

.blog .card .image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  aspect-ratio: 3/2;
}

.blog .card .image-holder .blog-img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog .card .text-holder p {
  font-size: 12px !important;
  color: #191919;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical;
  margin-bottom: 2%;
}

.blog .card .text-holder h3 {
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog .card .read-more-align .readmore-p {
  font-size: 12px;
  color: #faa700;
  margin-bottom: 0px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.blog .card .read-more-align {
  text-align: right;
}

.blog .recent-post-div {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 25px 25px;
}

.blog .recent-post-div .title {
  font-size: 16px;
  font-weight: 700;
}
.post {
  aspect-ratio: 2/2;
}
.blog .recent-post-div .recent-post-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: top;
}

.blog .post-title {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.blog .post-date {
  color: #000;
  font-size: 12px;
}

.blog a {
  color: #000;
}

.blog .card .card-body {
  padding: 0px 20px 0px 0px;
}
.blog .card .card-body br{
  display: none !important;
}
.blog .card .card-body h2{
  margin-bottom: 0px !important;
}

@media (min-width: 0px) and (max-width: 767px) {
  .blog .card .text-holder h3 {
    font-size: 14px;
  }

  .blog .card {
    padding: 13px 10px 16px 11px;
  }

  .blog .card .text-holder {
    margin-top: 5px;
  }
}

@media (min-width: 0px) and (max-width: 420px) {
  .blog {
    margin-bottom: 40%;
  }
}

@media (min-width: 421px) and (max-width: 577px) {
  .blog {
    margin-bottom: 35%;
  }
}

@media (min-width: 578px) and (max-width: 767px) {
  .blog {
    margin-bottom: 25%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .blog {
    margin-bottom: 20%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .blog {
    margin-bottom: 18%;
  }

  .blog .recent-post-div {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 25px 14px;
  }
}
