/* .card-hover {
    opacity: 0.95;
}
.card-hover:hover {
    opacity: 1;
}
.card-hover:hover .card-body {
    background-color: #dddcdc;
}
.card-hover:hover .card {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.otp-field {
    width: 38px;
    margin-right: 10px;
    padding-left: 12px;
    height: 40px;
}
.cursor-pointer {
    cursor: pointer;
} */

.scroll-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
}

.scroll-container .indicator {
    height: 100%;
    background: #8b0000;
}

.scroll-to-top {
    position: fixed;
    width: 70px;
    height: 70px;
    bottom: 120px;
    right: 20px;
    background: transparent linear-gradient(180deg, #000 0%, #faa700 100%);
    color: #fff;
    border-radius: 50px;
    text-align: center;
    z-index: 100;
    font-size: 34px;
}





.vert-move {
    -webkit-animation: mover 0.8s infinite alternate;
    animation: mover 0.8s infinite alternate;
}

.vert-move {
    -webkit-animation: mover 0.8s infinite alternate;
    animation: mover 0.8s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}



@media (min-width:0px) and (max-width:991px) {
    .scroll-to-top {
        width: 40px;
        height: 40px;
        font-size: 20px;
    }
}