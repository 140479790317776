/* Popular Categories css Started */

.Popular-Categories {
  margin: 5% 0;
  position: relative;
}

.Popular-Categories .main-section {
  position: relative;
  transition: transform 0.4s ease-in-out;
  border-radius: 10px;
  aspect-ratio: 4/4;
}

.Popular-Categories .main-section:hover {
  transform: translateY(-15px);
}

.Popular-Categories .main-section .products {
  height: 100%;
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.Popular-Categories .main-section .bg-overlay {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  background: transparent
    linear-gradient(
      180deg,
      #0f376800 0%,
      #0d336020 29%,
      #031a36d2 84%,
      #00142b 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.Popular-Categories .main-section .text-holder {
  position: absolute;
  bottom: 0px;
  left: 36%;
  transform: translate(-21%, -50%);
  z-index: 1;
}

.Popular-Categories .main-section .text-holder .product-text {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.Popular-Categories .silder-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
}

.Popular-Categories .mySwiper {
  position: relative;
}

.Popular-Categories .silder-btn .back-btn {
  content: url("../../../../public/assets/Image/Homepage/Popular-Categories/left-1.png");
  width: 24px;
  height: 24px;
  /* margin: 10px; */
  cursor: pointer;
  position: absolute;
  /* bottom: 205px; */
  z-index: 999;
  left: 0px;
  filter: hue-rotate(45deg);
  filter: brightness(3);
}

.Popular-Categories .silder-btn .next-btn {
  /* content: url("../../../../public/assets/Image/Homepage/Popular-Categories/right-1.png"); */
  content: url('../../../../public/assets/Image/Homepage/Popular-Categories/right-1.png');
  width: 24px;
  height: 24px;
  /* margin: 10px; */
  cursor: pointer;
  position: absolute;
  /* bottom: 205px; */
  z-index: 999;
  right: 0px;
  filter: hue-rotate(45deg);
  filter: brightness(3);
}

/* .Popular-Categories .swiper-button-prev.swiper-button-disabled,
.Popular-Categories .swiper-button-next.swiper-button-disabled {
  display: none;
} */

/* 
.Popular-Categories .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #df4223;
}

.Popular-Categories .swiper-pagination-bullet {
  background: #df4223;
  opacity: 1;
} */

/* Popular Categories css End */

/* Media Query PopularCategories Started */
@media (min-width: 0px) and (max-width: 420px) {
  .Popular-Categories .main-section .text-holder {
    left: 43%;
    transform: translate(-21%, -48%);
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .Popular-Categories .silder-btn .back-btn {
    width: 20px;
    height: 20px;
    /* left: -8px; */
  }

  .Popular-Categories .silder-btn .next-btn {
    width: 20px;
    height: 20px;
    /* right: -9px; */
  }

  .Popular-Categories .main-section .text-holder .product-text {
    font-size: 16px;
  }

  .Popular-Categories .main-section .products {
    /* height: 300px; */
  }

  .Popular-Categories .main-section {
    aspect-ratio: 1/1;
    border-radius: 10px;
    position: relative;
    transition: transform 0.4s ease-in-out;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Popular-Categories .main-section .text-holder .product-text {
    font-size: 16px;
  }
}
