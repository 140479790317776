.form-section .account-form {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5px 0px #ccc;
  padding: 30px;
  margin-top: 20px;
  border-radius: 5px;
}

.form-section .account-form .react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  width: 100%;
  border: 0;
}

.account-form .form-title {
  font-size: 16px;
  font-weight: 600;
  color: #323232;
}
.account-form a {
  text-decoration: none;
}
.account-form .edit {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  background-color: #ffce00;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  color: #000000;
  cursor: pointer;
}
.account-form .edit:hover {
  background-color: #d7b72f;
}
.account-form .label-set {
  font-size: 14px;
  font-weight: 500;
  color: #323232;
}

.account-form .form-control {
  font-size: 14px;
  background-color: #f6f6f6;
  border: 0px solid #ced4da;
  border-radius: 20px;
}

.account-form .form-control:focus {
  box-shadow: none;
  background-color: #f6f6f6;
}
.account-form .user-prof-img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  border: 1px solid #707070;
  padding: 4px;
}

.account-information .form-section .profile-img-icon-holder {
  position: relative;
  width: fit-content;
  margin: 0px auto;
}

.account-information .form-section .plus-icon-holder {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #faa700;
  color: #000;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.congrats_text {
  margin-top: 30px;
}

.congrats_text h3 {
  font-size: 20px;
  font-weight: 600;
  color: #bb2b2b;
  margin-bottom: 0px;
}

@media (max-width: 484px) {
  .account-information .title {
    font-size: 16px;
    padding: 10px;
  }
  .form-section .account-form {
    padding: 20px;
  }
  .account-form .form-title {
    font-size: 16px;
  }
  .account-form .label-set {
    font-size: 13px;
  }
  .account-form .edit {
    font-size: 12px;
  }
}

@media (min-width: 485px) and (max-width: 574px) {
  .account-information .title {
    font-size: 20px;
  }
  .account-form .form-title {
    font-size: 18px;
  }
}
@media (min-width: 575px) and (max-width: 767px) {
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 992px) and (max-width: 1023px) {
}
@media (min-width: 1024px) and (max-width: 1199px) {
}
@media (min-width: 1200px) and (max-width: 1399px) {
}
