.Membership .Membership-banner-img .Membership-banner {
  width: 100%;
  height: 100%;
}

.Membership .Membership-card-holder {
  margin-top: 3%;
}

.Membership .relation-div {
  position: relative;
}

.Membership .relation-div .absolute-content {
  position: absolute;
  width: 100%;
  top: 50px;
}

.Membership {
  margin-top: 2%;
  margin-bottom: 650px !important;
}

.Membership .Membership-card-holder .Membership_card_swiper {
  padding: 50px 20px;
}


.Membership .swiper-button-prev:after,
.Membership .swiper-button-next:after {
  font-size: 20px !important;
  text-transform: none !important;
  background: #faa700;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  width: 25px;
  text-align: center;
  padding: 5px;
  color: white;
}

.custom-tooltip {
  background-color: #faa700;
  color: black;
  position: relative;
}


/* .custom-tooltip .inner_tooltip {
  background-color: '#fece00';
  color: 'black';
  border: "1px solid yellow";
  width: "200px";
  font-weight: "600";
  padding: "10px";
  top: "-60px";
  left: "-80px";
  z-index: 9;
} */

@media (min-width:0px) and (max-width:991px) {
  .Membership .relation-div .absolute-content {
    position: unset !important;
  }

  .Membership_card .card-holder {
    margin: 10px;
  }
}