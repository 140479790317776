.select-categories {
  margin-top: 2%;
  margin-bottom: 0%;
}

.select-categories .nav-pills .nav-link.active {
  background: transparent;
}

.select-categories .view-all {
  /* margin-top: 2%; */
  text-align: end;
}

.select-categories .product-image {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  aspect-ratio: 3/2;
}
.select-categories .product-image .category-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: 0.3s;
}

.select-categories .product-image:hover .category-image {
  transform: scale(1.1);
  transition: 0.3s;
}

.select-categories .product-image .text-holder {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
  width: 100%;
  /* Ensure the entire width is centered */
}

.select-categories .product-image .text-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.select-categories .product-image .bg-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent
    linear-gradient(
      180deg,
      #0f376800 0%,
      #0d336020 29%,
      #031a36d2 84%,
      #00142b 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.select-categories .product-slide {
  width: 13% !important;
}

.select-categories .mySwiper1 {
  margin-left: 0%;
}

.select-categories .nav-pills .nav-link.active {
  background: transparent;
  border-radius: 0%;
  position: relative;
}

.select-categories .nav-pills .nav-link {
  padding-left: 10px;
  padding-right: 0px;
  margin-bottom: 20px;
}

.select-categories .nav-pills .nav-link.active .triangle-right {
  display: block !important;
  /* border-top: 7px solid transparent;
  border-left: 13px solid #DF4223;
  border-bottom: 7px solid transparent; */
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #df4223;
  position: absolute;
  left: 47%;
  bottom: -8%;
  z-index: 99999;
  /* transform: rotate(91deg); */
}

.select-categories .nav-pills .nav-link .triangle-right {
  display: none;
  z-index: 99999;
}

.select-categories .select-categories-slider {
  padding: 10px 0px;
}

.select-categories .desktop_view_product_category_slider {
  display: block;
}

.select-categories .mobile_view_product_category_slider {
  display: none;
}

.select-categories .selectbycattnav .nav-pills .nav-link.active {
  padding-left: 0px !important;
}

.select-categories .swiper-button-next:after {
  font-size: 20px;
  background: #fff;
  padding: 5px;
  color: #000;
}

.select-categories .swiper-button-prev:after {
  font-size: 20px;
  background: #fff;
  padding: 5px;
  color: #000;
}

.select-categories .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 0px) !important;
  left: auto;
}

.select-categories .swiper-button-prev, .select-categories .swiper-button-next {
  top: 60px;
  display: none;
}

.select-categories  .silder-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
}
.select-categories  .silder-btn .back-btn {
  content: url("../../../../public/assets/Image/Homepage/Popular-Categories/left-1.png");
  width: 24px;
  height: 24px;
  /* margin: 10px; */
  cursor: pointer;
  position: absolute;
  /* bottom: 205px; */
  z-index: 999;
  left: 0px;
  filter: hue-rotate(45deg);
  filter: brightness(3);
}

.select-categories  .silder-btn .next-btn {
  /* content: url("../../../../public/assets/Image/Homepage/Popular-Categories/right-1.png"); */
  content: url('../../../../public/assets/Image/Homepage/Popular-Categories/right-1.png');
  width: 24px;
  height: 24px;
  /* margin: 10px; */
  cursor: pointer;
  position: absolute;
  /* bottom: 205px; */
  z-index: 999;
  right: 0px;
  filter: hue-rotate(45deg);
  filter: brightness(3);
}
@media (min-width: 0px) and (max-width: 991px) {
  .select-categories .product-image .text-holder p {
    font-size: 14px;
  }

  .select-categories .desktop_view_product_category_slider {
    display: none;
  }

  .select-categories .mobile_view_product_category_slider {
    display: block;
  }

  
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .select-categories .product-slide {
    width: 19% !important;
  }
  }

@media (min-width: 992px) and (max-width: 1023px) {
.select-categories .product-slide {
  width: 19% !important;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .select-categories {
    margin-bottom: 18%;
  }

  .select-categories .product-slide {
    width: 20% !important;
}
}

@media (min-width: 578px) and (max-width: 767px) {
  .select-categories {
    margin-bottom: 20%;
  }

  .select-categories .product-slide {
    width: 30% !important;
  }
}

@media (min-width: 425px) and (max-width: 577px) {
  .select-categories {
    margin-bottom: 20%;
  }

  .select-categories .product-slide {
    width: 30% !important;
}
}

@media (min-width: 0px) and (max-width: 420px) {
  .select-categories {
    margin-bottom: 35%;
  }

  .select-categories .product-slide {
    width: 50% !important;
  }
}
