.careermain-sec {
    margin: 3% 0% 20% 0%;
}

.careermain-sec .title-div h3 {
    border-bottom: 5px solid;
    font-weight: 700;
    width: fit-content;
    margin: auto;
}

.careermain-sec .career-card {
    border: 1px solid #ccc;
    padding: 5px;
    width: 100%;
    height: 100%;
    /* aspect-ratio: 1/1; */
    border-radius: 5px;
}
.careermain-sec .career-card:hover{
    background-color: #effffa;
}

.careermain-sec .career-card .jobtime {
    color: #fff;
    font-size: 12px;
    background-color: #000;
    padding: 2px 7px;
    margin-bottom: 0px;
    border-radius: 4px;
    width: fit-content;
}

.careermain-sec .career-card .job-details {
    text-align: center;
}

.careermain-sec .main-banner {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/0.3;
}

.careermain-sec .career-card .job-details .iconn {
    font-size: 50px;
    color: #a9ead7;
    margin: 30px 0px;
}

.careermain-sec .career-card .job-details .pos-name {
    font-size: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.careermain-sec .career-card .job-details .add-p {
    margin: 20px 0px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
}

.careermain-sec .career-card .job-details .addicc {
    color: #a9ead7;
    margin-right: 4px;
}

.careermain-sec .career-card .top-div {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.careermain-sec .career-card .exppp{
    font-size: 13px;
}
.careermain-sec .career-card  .d-flex{
    justify-content: space-between;
    margin-top: 15px;
}
.careermain-sec .career-card  .d-flex .applybtn {
    color: #fff;
    font-size: 14px;
    background: #f7ad13;
    padding: 10px;
    border-radius: 5px;
}


.careermain-sec .career-card  .d-flex .applybtn:hover {
    background: #ffc107;
}

@media (min-width:0px) and (max-width:991px){
    .careermain-sec {
        margin: 3% 0% 30% 0%;
    }
}