.All-boom-content {
    margin-top: 3%;
    margin-bottom: 20%;
}

.All-boom-content .cont-sec ol li {
    margin-bottom: 8px;
    margin-top: 5px;
    font-size: 15px;
    list-style: decimal;
}

.All-boom-content .cont-sec ol li ul li {
    list-style: disc !important;
    font-weight: 400;
}

.All-boom-content .cont-sec .listmain{
    font-weight: 600;
}

.All-boom-content .cont-sec h6{
    font-weight: 600;
    font-size: 18px;
}

.All-boom-content  .Become_inquiry_form {
    box-shadow: 0px 0px 5px 0px #c0c0c0;
    border-radius: 5px;
    padding: 20px;
    position: sticky !important;
    top: 12%;
}

.All-boom-content  .Become_inquiry_form .form-label{
    font-weight: 500;
}
.All-boom-content  .Become_inquiry_form .form-group{
    margin-bottom: 10px;
}

.All-boom-content  .Become_inquiry_form .form-group .form-control {
    font-size: 14px;
}
.All-boom-content  .Become_inquiry_form .form-group .form-select {
    font-size: 14px;
}

.All-boom-content  .Become_inquiry_form .submit-btn , .All-boom-content  .Become_inquiry_form .submit-btn:hover{
    background: #212121 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: #000;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    padding: 8px 26px;
    border-radius: 50px;
    margin-top: 10px;
}


.All-boom-content .bannerr {
    width: 100%;
    margin-bottom: 20px;
    /* aspect-ratio: 1/0.4; */
    height: auto ;
    object-fit: contain;
}
.All-boom-content .job-details .jobimg{
    width: 100%;
    margin-bottom: 20px;
    aspect-ratio: 1/0.4;
    object-fit: contain;
}
.All-boom-content .pcont{
    font-size: 14px;
}

.boomtitle{
    background: transparent
    linear-gradient(90deg, #ffffff 0%, #faa700 51%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-bottom: 40px;
}

.number-text-holder {
    margin-top: 60px;
}

.number-text-holder  .heading-holder {
    font-size: 20px;
    font-weight: 600;
}

.number-text-holder  .number-text {
    font-size: 18px;
    font-weight: 500;
    margin-left: 5px;
}


@media (min-width:0px) and (max-width:991px){
    .All-boom-content {
        margin-bottom: 35%;
}
}