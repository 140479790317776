.My_Coupons {
  margin-bottom: 125px;
  margin-top: 30px;
}

.Coupons_holder {
  margin-top: 25px;
}

.Coupons_holder .Coupons_Swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc((100% - 30px) / 2) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .Coupons_holder .Coupons_Swiper {
  height: 300px;
  padding: 15px 0px;
} */

.Coupons_holder .desktop-swiper {
  height: 342px;
}

.Coupons_holder .Coupons_Swiper {
  padding: 25px 0px;
}

.Coupons_holder .desktop-swiper {
  display: block;
}

.Coupons_holder .mobile-swiper {
  display: none;
}

@media (min-width: 0px) and (max-width: 992px) {
  .Coupons_holder .desktop-swiper {
    display: none;
  }

  .Coupons_holder .mobile-swiper {
    display: block;
  }

}

