
.Career_description{
    margin: 3% 0% 20% 0%;
}
.Career_description .sec-1 ul li {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
}

.Career_description .sec-1 {
    background: #f6f6f6;
    padding: 20px;
    height: 100%;
}
.Career_description  .form-group{
    margin-bottom: 10px;
}

.Career_description  .proceed-bttn{
    color: #fff;
    background-color: #f7ad13;
    border: 0px;
    width: 100%;
    padding: 10px;
}

.Career_description  .sec-2{
    box-shadow: 0px 0px 5px 0px #ccc;
    padding: 20px;
    border-radius: 5px;
}
.Career_description  .sec-2 .form-control{
    font-size: 14px;
}
.Career_description .jobdisc{

}
@media (min-width:0px) and (max-width:991px){
    .Career_description {
        margin: 3% 0% 30% 0%;
    }
}